import * as React from 'react';
import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Pokemon } from '../types/Pokemon';
import getPokemonName from '../util/pokeFormatting';
import PokemonImage from './PokemonImage';


function formatAbilities(pokemon: Pokemon) {
    let abilityString = pokemon.Ability1;
    if (pokemon.Ability2) {
        abilityString += '\n' + pokemon.Ability2;
    }
    if (pokemon.Ability3) {
        abilityString += '\n' + pokemon.Ability3;
    }
    return abilityString;
}

function getOverallStatsRow(allMons: Pokemon[], showTier: boolean) {
    if (!allMons || allMons.length === 0) {
        return;
    }

    let numMonsInTable = 0;
    let overall = {
        Total: 0,
        Hp: 0,
        Attack: 0,
        Defense: 0,
        SpAtk: 0,
        SpDef: 0,
        Speed: 0
    };

    for (const index in allMons) {
        const mon = allMons[index];
        if (!mon) {
            continue;
        }

        overall.Total += Number(mon.Total);
        overall.Hp += Number(mon.Hp);
        overall.Attack += Number(mon.Attack);
        overall.Defense += Number(mon.Defense);
        overall.SpAtk += Number(mon.SpAtk);
        overall.SpDef += Number(mon.SpDef);
        overall.Speed += Number(mon.Speed);
        numMonsInTable++;
    }

    overall.Total = Number((overall.Total / numMonsInTable).toFixed(2));
    overall.Hp = Number((overall.Hp / numMonsInTable).toFixed(2));
    overall.Attack = Number((overall.Attack / numMonsInTable).toFixed(2));
    overall.Defense = Number((overall.Defense / numMonsInTable).toFixed(2));
    overall.SpAtk = Number((overall.SpAtk / numMonsInTable).toFixed(2));
    overall.SpDef = Number((overall.SpDef / numMonsInTable).toFixed(2));
    overall.Speed = Number((overall.Speed / numMonsInTable).toFixed(2));

    let cells = [];
    cells.push(
        <TableCell key={'overallrow'}>
            Average
        </TableCell>
    )

    // Add empty cell for tier column if we're showing tiers
    if (showTier) {
        cells.push(<TableCell />);
    }    
    cells.push(<TableCell />);
    cells.push(<TableCell />);
    cells.push(<TableCell />);

    let type: keyof typeof overall; // Type is "Total" | "Hp" | "Attack"...
    for (type in overall) {
        cells.push
            (
                <TableCell key={'average' + type}>
                    {overall[type]}
                </TableCell>
            );
    }

    return (
        <TableRow key={'overall'} sx={{}}>
            {cells.map((cell) => cell)}
        </TableRow>
    );
}

function getRowForPokemon(mon: Pokemon, index: number, showTier: boolean) {
    if (!mon) {
        return;
    }

    let pokemonName = getPokemonName(mon);

    let cells = [];
    cells.push(
        <TableCell key={pokemonName + 'Name' + index}>
            {mon && (<PokemonImage pokemon={mon} />)}
            {pokemonName}
        </TableCell>
    );
    if (showTier) {
        cells.push(
            <TableCell key={pokemonName + 'Tier' + index}>
                {mon.Tier}
            </TableCell>
        );
    }    
    cells.push(
        <TableCell key={pokemonName + 'Type1' + index}>
            {mon.Type1}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Type2' + index}>
            {mon.Type2}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Abilities' + index} sx={{ whiteSpace: "pre-line" }}>
            {formatAbilities(mon)}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Total' + index}>
            {mon.Total}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'HP' + index}>
            {mon.Hp}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Attack' + index}>
            {mon.Attack}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Defense' + index}>
            {mon.Defense}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'SpAtk' + index}>
            {mon.SpAtk}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'SpDef' + index}>
            {mon.SpDef}
        </TableCell>
    );
    cells.push(
        <TableCell key={pokemonName + 'Speed' + index}>
            {mon.Speed}
        </TableCell>
    );

    return (
        <TableRow key={pokemonName + 'statsrow' + index}>
            {cells.map((cell) => cell)}
        </TableRow>
    );
};

function getRowsForPokemon(pokemon: Pokemon[], showTier: boolean) {
    if (!pokemon) {
        return [];
    }


    const sortedMon = showTier
        ? [...pokemon].sort(function (a, b) { return b.Tier - a.Tier })
        : [...pokemon].sort(function (a, b) { return b.Total - a.Total });

    
    const rows = sortedMon.map((mon, index) => getRowForPokemon(mon, index, showTier));

    let emptyCells = [];
    for (let i = 0; i < 12; i++) {
        emptyCells.push(
            <TableCell key={'emptycell' + i} sx={{ paddingTop: 4 }} />
        );
    }

    rows.push(
        <TableRow key={'emptyrow'} sx={{ padding: 10 }}>
            {emptyCells.map((cell) => cell)}
        </TableRow>
    );

    return rows;
}

type Props = {
    pokemon: Pokemon[],
    teamId?: number,
    showTier: boolean,
}

export default function TeamStatsTable({ pokemon, teamId, showTier }: Props) {
    const rows = getRowsForPokemon(pokemon, showTier);
    const overallRow = getOverallStatsRow(pokemon, showTier);

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    Team Stats
                </Typography>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'header'}>
                                <TableCell key={'Pokemon'}>Pokemon</TableCell>
                                {showTier && (
                                    <TableCell key={'Tier'}>Tier</TableCell>
                                )}                                
                                <TableCell key={'Type 1'}>Type 1</TableCell>
                                <TableCell key={'Type 2'}>Type 2</TableCell>
                                <TableCell key={'Ability'}>Ability</TableCell>
                                <TableCell key={'Total'}>Tot</TableCell>
                                <TableCell key={'HP'}>HP</TableCell>
                                <TableCell key={'Attack'}>Atk</TableCell>
                                <TableCell key={'Defense'}>Def</TableCell>
                                <TableCell key={'SpAtk'}>SpA</TableCell>
                                <TableCell key={'SpDef'}>SpD</TableCell>
                                <TableCell key={'Speed'}>Spe</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                            <TableRow />
                            {overallRow}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}