import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { RequestStatus } from '../../types/RequestStatus';
import { Token } from '../../types/Token';
import { Team } from '../../types/Team';
import useToken from '../../useToken';
import RequestStatusIndicator from '../RequestStatusIndicator';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

interface GetUserData {
    DisplayName: string,
    DiscordUsername: string,
    ShowdownName: string,
}

async function getUserRequest(token: Token, setData: (s: any) => void, setLoading: (b: boolean) => void) {
    fetch('/User/Settings/', {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        setLoading(false);

        if (!response.ok) {
            return;
        }

        setData(message);
    });
}

interface SendUpdateRequest {
    DisplayName: any,
    DiscordUsername: any,
    ShowdownName: any,
}

async function sendUpdateRequest(request: SendUpdateRequest, token: Token, setUpdateRequestStatus: (s: RequestStatus) => void) {
    fetch('/User/Settings', {
        method: 'PUT',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            setUpdateRequestStatus(RequestStatus.Failed);
            return;
        }

        setUpdateRequestStatus(RequestStatus.Successful);
    });
}

interface Props {}

export default function UserSettingsTab({}: Props) {
    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<GetUserData>();
    const [updateRequestStatus, setUpdateRequestStatus] = useState<RequestStatus>(RequestStatus.None);

    useEffect(() => {
        if (token && loading) {
            getUserRequest(token, setData, setLoading);
        }
    }, [token, loading]);

    const submitUpdate = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        await sendUpdateRequest({
            DisplayName: data.get('displayName'),
            DiscordUsername: data.get('discordid'),
            ShowdownName: data.get('showdownName'),
        }, token, setUpdateRequestStatus);
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{ mt: 1, height: '650px' }}>
                {loading && (
                    <Box>
                        <CircularProgress />
                    </Box>
                )}
                {data?.DisplayName && !loading && (
                    <Box>
                        <Typography variant="h6">
                            User Settings
                        </Typography>
                        <Box component="form" onSubmit={submitUpdate}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="displayName"
                                label="Display Name"
                                name="displayName"
                                defaultValue={data.DisplayName}
                            />
                            <Box sx={{ mt: 2, mb: 2 }}>
                                <TextField
                                    label="Discord User Id"
                                    id="discordid"
                                    name="discordid"
                                    autoComplete="discordid"
                                    helperText="Optional. Used to ping you when it's your turn to draft"
                                    defaultValue={data.DiscordUsername}
                                />
                                <Tooltip title="Your Discord User Id is not your username. It is a 17 or 18 digit number that can be found in your Discord settings. Click me for more information. If you don't know what this or don't use Discord you can leave this blank.">
                                    <InfoOutlinedIcon sx={{ ml: 0.5, mt: "15px" }} onClick={() => window.open("https://tinyurl.com/yc3jft2t", "_blank", "noopener,noreferrer")} />
                                </Tooltip>
                            </Box>
                            <TextField
                                label="Pokemon Showdown Username"
                                id="showdownName"
                                name="showdownName"
                                autoComplete="showdownName"
                                helperText="Optional. Used to track your matches"
                                defaultValue={data.ShowdownName}
                            />
                            <Box sx={{ display: 'flex', mt: 3, mb: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Update
                                </Button>
                                <Box sx={{ mt: '6px', ml: '6px' }} >
                                    <RequestStatusIndicator status={updateRequestStatus} />
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
}

