import React from "react";
import { Box, Typography } from "@mui/material";
import { DraftPick } from "../../../types/DraftPick";
import { Pokemon } from "../../../types/Pokemon";
import getPokemonName from "../../../util/pokeFormatting";
import { useTheme } from '@mui/material/styles';
import PokemonImage from "../../PokemonImage";


interface DraftCellProps {
    draftPick: DraftPick,
    pokemonMap: Map<number, Pokemon>,
    highlight: boolean,
}

export default function DraftCell({ draftPick, pokemonMap, highlight }: DraftCellProps) {

    const pokemon = pokemonMap.get(draftPick.PokemonUniqueId);
    const pokemonName = pokemon ? getPokemonName(pokemon) : "";

    const theme = useTheme();
    const neutralColor = theme.palette.neutral.main;

    const backgroundColor = highlight ? '#ab47bc' : '#21283c';

	return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: backgroundColor,
            borderRadius: '10px',
            paddingTop: '4px',
            paddingLeft: '8px',
            margin: '1px',
            height: '50px',
            minWidth: '150px',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ fontSize: '11px', color: neutralColor }}>{draftPick.Pick}</Box>
                <Typography variant="body2" noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '86px' }}>
                    {pokemonName}
                </Typography>
            </Box>
            
            {pokemon && (
                <PokemonImage pokemon={pokemon} />
            )}
        </Box>
	)
}