import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import getPokemonName from '../../util/pokeFormatting';
import { StatLeader } from './StatLeaders';
import { Box } from '@mui/material';

interface AllStatsTableProps {
	stats: StatLeader[];
}

export default function AllStatsTable(props: AllStatsTableProps) {
	if (!props.stats) {
		return null;
	}

	const columns = [
		{
			field: 'pokemon',
			headerName: 'Pokemon',
			width: 150,
		},
		{
			field: 'kills',
			headerName: 'Kills',
			type: 'number',
			width: 75,
		},
		{
			field: 'deaths',
			headerName: 'Deaths',
			type: 'number',
			width: 75,
		},
		{
			field: 'damage',
			headerName: 'Damage',
			type: 'number',
			width: 75,
		},
		{
			field: 'hazard',
			headerName: 'Hazard',
			type: 'number',
			width: 75,
		},
		{
			field: 'status',
			headerName: 'Status',
			type: 'number',
			width: 75,
		},
		{
			field: 'weather',
			headerName: 'Weather',
			type: 'number',
			width: 75,
		},
		{
			field: 'healed',
			headerName: 'Healed',
			type: 'number',
			width: 75,
		},
		{
			field: 'damage-taken',
			headerName: 'Damage Taken',
			type: 'number',
			width: 125,
		},
	];

	const rowsToUse = props.stats.map((stat, index) => {
		return {
			id: index,
			pokemon: getPokemonName(stat.Pokemon),
			kills: stat.Kills,
			deaths: stat.Deaths,
			damage: stat.DamageDealt,
			hazard: stat.HazardDamageDealt,
			status: stat.StatusDamageDealt,
			weather: stat.WeatherDamageDealt,
			healed: stat.DamageHealed,
			'damage-taken': stat.DamageTaken,
		};
	});

	return (
		<Box sx={{ height: '400px' }}>
			<DataGrid
				rows={rowsToUse}
				columns={columns}
				pageSize={100}
				disableSelectionOnClick
				initialState={{
					sorting: {
						sortModel: [{ field: 'kills', sort: 'desc' }],
					},
				}}
			/>
		</Box>		
	);
}
