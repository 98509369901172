import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { PokemonTeam } from '../../../../types/PokemonTeam';
import { DEFAULT_TIME } from './MatchupCard';
import { Schedule } from './Matchups';


interface MatchupEditorProps {
	pendingSchedule: Schedule[],
    setPendingSchedule: (matches: Schedule[]) => void,
    teams: PokemonTeam[],
    selectedWeek: string,
    setSelectedWeek: (week: string) => void,
}

export default function MatchupEditor({ pendingSchedule, setPendingSchedule, teams, selectedWeek, setSelectedWeek }: MatchupEditorProps) {

    const addMatchup = () => {
        const newMatchup = {
            Id: "0",
            TeamOneId: 0,
            TeamTwoId: 0,
            Week: Number(selectedWeek),
        };
        setPendingSchedule([...pendingSchedule, newMatchup]);
    };

    const addWeek = () => {
        // Get the highest week number from the pending schedule and add 1
        const newWeek = Math.max(...pendingSchedule.map((schedule: Schedule) => schedule.Week)) + 1;
        const newMatchup = {
            Id: "0",
            TeamOneId: 0,
            TeamTwoId: 0,
            Week: newWeek,
        };
        setPendingSchedule([...pendingSchedule, newMatchup]);
        setSelectedWeek(newWeek.toString());
    };

    const removeWeek = () => {
        const newSchedule = pendingSchedule.filter(
            (schedule: Schedule) => schedule.Week !== Number(selectedWeek)
        );

        setPendingSchedule(newSchedule);

        // Get the next highest week number from the pending schedule
        const newWeek = Math.max(...newSchedule.map((schedule: Schedule) => schedule.Week));
        setSelectedWeek(newWeek.toString());
    };

    const updateTeamSelection = (index: number, team: string, value: number) => {
        const newSchedule = [...pendingSchedule];
        const thisWeekSchedule = newSchedule.filter((schedule: Schedule) => schedule.Week === Number(selectedWeek));
        if (team === 'team1') {
            thisWeekSchedule[index].TeamOneId = value;
        }
        else {
            thisWeekSchedule[index].TeamTwoId = value;
        }        
        setPendingSchedule(newSchedule);
    };

    const deleteMatch = (index: number) => {
        const newSchedule = pendingSchedule.filter(
            (schedule: Schedule) => schedule.Week !== Number(selectedWeek)
        );
        const thisWeekSchedule = pendingSchedule.filter(
            (schedule: Schedule) => schedule.Week === Number(selectedWeek)
        );
        thisWeekSchedule.splice(index, 1);
        setPendingSchedule([...newSchedule, ...thisWeekSchedule]);
    };

	return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Button variant="outlined" color="primary" onClick={() => addMatchup()}>
                    Add Matchup
                </Button>
                <Button variant="outlined" color="primary" onClick={() => addWeek()} sx={{ ml: 1 }}>
                    Add Week
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => removeWeek()} sx={{ ml: 1 }}>
                    Remove Week
                </Button>
            </Box>
			{pendingSchedule
				.filter((schedule: Schedule) => schedule.Week === Number(selectedWeek))
				.sort((a: Schedule, b: Schedule) => {
					if (a.ScheduledTime === null && b.ScheduledTime === null) return 0; // Both null, no change
					if (a.ScheduledTime === DEFAULT_TIME) return 1; // a is null, move it to the end
                    if (b.ScheduledTime === DEFAULT_TIME) return -1; // b is null, move it to the end
                    if (a.ScheduledTime === undefined || b.ScheduledTime === undefined) return 0; // Both undefined, no change
					return a.ScheduledTime.localeCompare(b.ScheduledTime); // Compare normally
				})
				.map((schedule: Schedule, index) => (
                    <Box key={`schedule-${selectedWeek}-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <FormControl sx={{ width: '300px' }}>
                            <InputLabel id={`team1-select-label-${selectedWeek}-${index}`}>Team 1</InputLabel>
                            <Select
                                labelId={`team1-select-label-${selectedWeek}-${index}`}
                                id={`team1-select-${selectedWeek}-${index}`}
                                value={schedule.TeamOneId === 0 ? '' : schedule.TeamOneId}
                                onChange={(e) => {
                                    updateTeamSelection(index, "team1", e.target.value as number);
                                }}
                            >
                                {teams.map((team) => (
                                    <MenuItem key={team.TeamId} value={team.TeamId}>
                                        {team.OwnerDisplayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Avatar sx={{ height: 36, width: 36 }}>vs</Avatar>
                        <FormControl sx={{ width: '300px' }}>
                            <InputLabel id={`team2-select-label-${selectedWeek}-${index}`}>Team 2</InputLabel>
                            <Select
                                labelId={`team2-select-label-${selectedWeek}-${index}`}
                                id={`team2-select-${selectedWeek}-${index}`}
                                value={schedule.TeamTwoId === 0 ? '' : schedule.TeamTwoId}
                                onChange={(e) => {
                                    updateTeamSelection(index, "team2", e.target.value as number);
                                }}
                            >
                                {teams.map((team) => (
                                    <MenuItem key={team.TeamId} value={team.TeamId}>
                                        {team.OwnerDisplayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => deleteMatch(index)}
                            sx={{ ml: 1 }}
                        >
                            Delete
                        </Button>
					</Box>
            ))}            
		</Box>
    );
}