import { Box, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { Pokemon } from '../../types/Pokemon';
import getPokemonName from '../../util/pokeFormatting';
import PokemonImage from '../PokemonImage';

export interface StatLeaderProps {
	pokemon: Pokemon;
	title: string;
	value: number;
}

export default function StatLeader(props: StatLeaderProps) {

	const caption = `${props.title}: ${props.value}`;

	return (
		<Paper elevation={5} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
			<Box>
				<Typography component="h6" variant="h6" gutterBottom>
					{caption}
				</Typography>
				{props.pokemon && (
					<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						<PokemonImage pokemon={props.pokemon} />
						<Typography variant="body2" color="neutral" sx={{ mb: 1, mt: 1 }}>
							{getPokemonName(props.pokemon)}
						</Typography>
					</Box>
				)}
			</Box>
		</Paper>		
	);
}