// Purpose: Provide a form for the user to reset their password after clicking a reset link in their email.
// The user will provide their email, a new password, and confirm the new password. When a button is clicked it calls the
// resetPassword() function which will send a POST request to the server to reset the user's password. If the request is
// successful, the user will be redirected to the login page. If the request fails, the user will be shown an error message.

import React, { Component } from 'react';
import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CssBaseline from '@mui/material/CssBaseline';
import { useHistory } from 'react-router-dom';

interface ResetRequest {
    Email: any;
    NewPassword: any;
    ResetToken: any;
}

async function reset(request: ResetRequest, history: any) {
    fetch('/Login/UpdatePassword/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }
        alert("Password reset successfully. Please log in with your new password.");
        history.push('/home');
    });
}

export default function ResetPassword() {
    let { resetToken } = useParams<{ resetToken: string }>();
    const history = useHistory();

    const submitReset = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (data.get('password') !== data.get('passwordTwo')) {
            alert("Passwords don't match");
            return;
        }
        await reset({
            Email: data.get('email'),
            NewPassword: data.get('password'),
            ResetToken: resetToken
        }, history);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={submitReset}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordTwo"
                        label="Re-enter New Password"
                        type="password"
                        id="passwordTwo"
                        autoComplete="new-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Reset Password
                    </Button>
                </Box>
            </Box>
        </Container>
    );              
}