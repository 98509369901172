import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { DraftPick } from '../../../types/DraftPick';
import { Pokemon } from '../../../types/Pokemon';
import DraftCell from './DraftCell';

interface DraftBoardProps {
    draftPicks: DraftPick[],
    pokemonMap: Map<number, Pokemon>,
}

export default function DraftBoard({ draftPicks, pokemonMap }: DraftBoardProps) {

    // Map of teamId to DraftPick[]
    const teamPicksMap = useMemo(() => {
        const newMap = new Map<number, DraftPick[]>();
        draftPicks.forEach((pick: DraftPick) => {
            if (newMap.has(pick.TeamId)) {
                newMap.get(pick.TeamId)?.push(pick);
            } else {
                newMap.set(pick.TeamId, [pick]);
            }
        });
        return newMap;
    }, [draftPicks]);

    const highlightedPicks = draftPicks.map((pick, index) => {
        if (index === 0 || draftPicks[index - 1].PokemonUniqueId > 0) {
            return pick.PokemonUniqueId === 0;
        }
        return false;
    });

	return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'auto' }}>
            {Array.from(teamPicksMap.entries()).map(([teamId, picks], indexR) => (
                <Box key={teamId} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: '150px', margin: '1px' }}>
                    <Typography variant="body2" gutterBottom noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center' }}>
                        {picks[0].OwnerDisplayName}
                    </Typography>
                    {picks.map((pick, indexC) => (
                        <DraftCell key={"draftcell" + indexR + "|" + indexC} draftPick={pick} pokemonMap={pokemonMap} highlight={highlightedPicks[pick.Pick-1]} />
                    ))}
                </Box>
            ))}
        </Box>
	);
}