import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Pokemon } from '../../types/Pokemon';
import getPokemonName from '../../util/pokeFormatting';
import PokemonImage from '../PokemonImage';


type Props = {
    pokemon?: Pokemon[],
    pickSet: Set<number>, // set of unique pokemon ids
    costEnabled: boolean,
}

export default function DraftTierTable({ pokemon, pickSet, costEnabled }: Props) {
    const rowMap = new Map();
    let maxRows = 0;
    let tiers = [];

    if (pokemon) {
        for (let i = 0; i < pokemon.length; i++) {
            if (!rowMap.has(pokemon[i].Tier)) {
                rowMap.set(pokemon[i].Tier, []);
                tiers.push(pokemon[i].Tier);
            }
            rowMap.get(pokemon[i].Tier).push(pokemon[i]);
            if (rowMap.get(pokemon[i].Tier).length > maxRows) {
                maxRows = rowMap.get(pokemon[i].Tier).length;
            }
        }
    }

    tiers.sort(function (a, b) { return b - a });

    // Build rows
    let rows = [];
    for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        // Build columns
        let cols = [];
        for (let j = 0; j < tiers.length; j++) {
            let potentialPokemon = rowMap.get(tiers[j])[rowIndex];
            if (potentialPokemon === undefined) {
                // empty cell
                cols.push("");
            }
            else {
                // cell with name
                cols.push(potentialPokemon);
            }
        }

        rows.push({ id: rowIndex, cols: cols });
    }

    let emptyCells = 0;
    const getCellForPokemon = (mon: Pokemon) => {
        let pokemonName = (mon) ? getPokemonName(mon) : "";
        let keyToUse;
        
        if (mon) {
            keyToUse = mon.UniqueId.toString();
        }
        else {
            keyToUse = 'empty' + emptyCells;
            emptyCells++;
        }

        let style = (pickSet.has(mon.UniqueId)) ? { backgroundColor: 'rgb(206, 147, 216)' } : {};

        return (
            <TableCell key={keyToUse} sx={style}>
                {mon && (<PokemonImage pokemon={mon} />)}
                {pokemonName}
            </TableCell>
        );

    };

    const tableLabel = costEnabled ? "Price List" : "Tier List";

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    {tableLabel}
                </Typography>
                <TableContainer sx={{ maxHeight: 800 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'header'}>
                                {tiers.map((tier) => (
                                    <TableCell key={'tier' + tier}>{tier}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.cols.map((col) => getCellForPokemon(col))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}