import * as React from 'react';

import ShieldIcon from '@mui/icons-material/Shield';
import BugReportIcon from '@mui/icons-material/BugReport';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

export default function BugBadge() {
    return (
        <Tooltip title="Bug Badge: Awarded for defending the website from a swarm of bugs">
            <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: 'relative',
                    width: 30,
                    height: 30
                }}
            >
                <ShieldIcon sx={{ color: '#4CAF50', fontSize: 30 }} />
                <BugReportIcon sx={{ color: '#FFEB3B', fontSize: 18, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />

            </Stack>
        </Tooltip>        
    );
}