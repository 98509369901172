import { Pokemon } from "../types/Pokemon";
import { Type } from "../types/PokemonType";

export interface WeaknessModifier {
    Normal: number,
    Fire: number,
    Water: number,
    Grass: number,
    Electric: number,
    Ice: number,
    Fighting: number,
    Poison: number,
    Ground: number,
    Flying: number,
    Psychic: number,
    Bug: number,
    Rock: number,
    Ghost: number,
    Dragon: number,
    Dark: number,
    Steel: number,
    Fairy: number,
}

export default function pokemonTypeWeakness() {
    const getWeaknessModifierForType = (type: Type) => {
        switch (type) {
            case Type.Normal:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 2,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 0,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Fire:
                return {
                    Normal: 1,
                    Fire: 0.5,
                    Water: 2,
                    Grass: 0.5,
                    Electric: 1,
                    Ice: 0.5,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 2,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 0.5,
                    Rock: 2,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 0.5,
                    Fairy: 0.5
                };
            case Type.Water:
                return {
                    Normal: 1,
                    Fire: 0.5,
                    Water: 0.5,
                    Grass: 2,
                    Electric: 2,
                    Ice: 0.5,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 0.5,
                    Fairy: 1
                };
            case Type.Grass:
                return {
                    Normal: 1,
                    Fire: 2,
                    Water: 0.5,
                    Grass: 0.5,
                    Electric: 0.5,
                    Ice: 2,
                    Fighting: 1,
                    Poison: 2,
                    Ground: 0.5,
                    Flying: 2,
                    Psychic: 1,
                    Bug: 2,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Electric:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 0.5,
                    Ice: 1,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 2,
                    Flying: 0.5,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 0.5,
                    Fairy: 1
                };
            case Type.Ice:
                return {
                    Normal: 1,
                    Fire: 2,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 0.5,
                    Fighting: 2,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 2,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 2,
                    Fairy: 1
                };
            case Type.Fighting:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 1,
                    Flying: 2,
                    Psychic: 2,
                    Bug: 0.5,
                    Rock: 0.5,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 0.5,
                    Steel: 1,
                    Fairy: 2
                };
            case Type.Poison:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 0.5,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 0.5,
                    Poison: 0.5,
                    Ground: 2,
                    Flying: 1,
                    Psychic: 2,
                    Bug: 0.5,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 0.5
                };
            case Type.Ground:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 2,
                    Grass: 2,
                    Electric: 0,
                    Ice: 2,
                    Fighting: 1,
                    Poison: 0.5,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 0.5,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Flying:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 0.5,
                    Electric: 2,
                    Ice: 2,
                    Fighting: 0.5,
                    Poison: 1,
                    Ground: 0,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 0.5,
                    Rock: 2,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Psychic:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 0.5,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 0.5,
                    Bug: 2,
                    Rock: 1,
                    Ghost: 2,
                    Dragon: 1,
                    Dark: 2,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Bug:
                return {
                    Normal: 1,
                    Fire: 2,
                    Water: 1,
                    Grass: 0.5,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 0.5,
                    Poison: 1,
                    Ground: 0.5,
                    Flying: 2,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 2,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Rock:
                return {
                    Normal: 0.5,
                    Fire: 0.5,
                    Water: 2,
                    Grass: 2,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 2,
                    Poison: 0.5,
                    Ground: 2,
                    Flying: 0.5,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 2,
                    Fairy: 1
                };
            case Type.Ghost:
                return {
                    Normal: 0,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 0,
                    Poison: 0.5,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 0.5,
                    Rock: 1,
                    Ghost: 2,
                    Dragon: 1,
                    Dark: 2,
                    Steel: 1,
                    Fairy: 1
                };
            case Type.Dragon:
                return {
                    Normal: 1,
                    Fire: 0.5,
                    Water: 0.5,
                    Grass: 0.5,
                    Electric: 0.5,
                    Ice: 2,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 2,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 2
                };
            case Type.Dark:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 2,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 0,
                    Bug: 2,
                    Rock: 1,
                    Ghost: 0.5,
                    Dragon: 1,
                    Dark: 0.5,
                    Steel: 1,
                    Fairy: 2
                };
            case Type.Steel:
                return {
                    Normal: 0.5,
                    Fire: 2,
                    Water: 1,
                    Grass: 0.5,
                    Electric: 1,
                    Ice: 0.5,
                    Fighting: 2,
                    Poison: 0,
                    Ground: 2,
                    Flying: 0.5,
                    Psychic: 0.5,
                    Bug: 0.5,
                    Rock: 0.5,
                    Ghost: 1,
                    Dragon: 0.5,
                    Dark: 1,
                    Steel: 0.5,
                    Fairy: 0.5
                };
            case Type.Fairy:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 0.5,
                    Poison: 2,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 0.5,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 0,
                    Dark: 0.5,
                    Steel: 2,
                    Fairy: 1
                };
            default:
                return {
                    Normal: 1,
                    Fire: 1,
                    Water: 1,
                    Grass: 1,
                    Electric: 1,
                    Ice: 1,
                    Fighting: 1,
                    Poison: 1,
                    Ground: 1,
                    Flying: 1,
                    Psychic: 1,
                    Bug: 1,
                    Rock: 1,
                    Ghost: 1,
                    Dragon: 1,
                    Dark: 1,
                    Steel: 1,
                    Fairy: 1
                };
        }
    };

    const getWeaknessModifierForPokemon = (mon: Pokemon) => {
        let weaknessMod = getWeaknessModifierForType(mon.Type1);

        if (mon.Type2) {
            let weaknessMod2 = getWeaknessModifierForType(mon.Type2);
            weaknessMod.Normal *= weaknessMod2.Normal;
            weaknessMod.Fire *= weaknessMod2.Fire;
            weaknessMod.Water *= weaknessMod2.Water;
            weaknessMod.Grass *= weaknessMod2.Grass;
            weaknessMod.Electric *= weaknessMod2.Electric;
            weaknessMod.Ice *= weaknessMod2.Ice;
            weaknessMod.Fighting *= weaknessMod2.Fighting;
            weaknessMod.Poison *= weaknessMod2.Poison;
            weaknessMod.Ground *= weaknessMod2.Ground;
            weaknessMod.Flying *= weaknessMod2.Flying;
            weaknessMod.Psychic *= weaknessMod2.Psychic;
            weaknessMod.Bug *= weaknessMod2.Bug;
            weaknessMod.Rock *= weaknessMod2.Rock;
            weaknessMod.Ghost *= weaknessMod2.Ghost;
            weaknessMod.Dragon *= weaknessMod2.Dragon;
            weaknessMod.Dark *= weaknessMod2.Dark;
            weaknessMod.Steel *= weaknessMod2.Steel;
            weaknessMod.Fairy *= weaknessMod2.Fairy;
        }

        return weaknessMod;
    };

    return {
        getWeaknessModifierForType,
        getWeaknessModifierForPokemon
    }
}