import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowModel, MuiBaseEvent, MuiEvent } from '@mui/x-data-grid';
import React, { useCallback, useState } from 'react';
import { Pokemon } from '../../types/Pokemon';
import { PokemonCostRow, SelectablePokemon } from './types';

const columnDefs: GridColDef[] = [
	{ field: 'pokemonId', headerName: 'Pokemon ID', width: 100 },
	{ field: 'name', headerName: 'Name', width: 150 },
	{ field: 'variation', headerName: 'Variation', width: 150 },
	{ field: 'generation', headerName: 'Generation', width: 100 },
	{ field: 'cost', headerName: 'Tier', editable: true, type: 'number', width: 150 },
];

interface CostTableProps {
	selectablePokemon: Map<number, SelectablePokemon> | undefined,
	pokemonMap: Map<number, Pokemon> | undefined,
	selectedRows: number[],
}

export default function CostTable({ selectablePokemon, pokemonMap, selectedRows }: CostTableProps) {
	const [rows, setRows] = useState<PokemonCostRow[]>([]);

	React.useEffect(() => {
		if (selectablePokemon !== undefined && pokemonMap !== undefined) {
			const newRows: PokemonCostRow[] = [];
			selectedRows.forEach((selectedRow) => {
				const pokemon = pokemonMap.get(selectedRow);
				const monSettings = selectablePokemon.get(selectedRow);

				if (!pokemon || !monSettings) {
					return;
				}

				newRows.push({
					id: monSettings.uniqueId,
					pokemonId: monSettings.pokemonId,
					cost: monSettings.cost ?? 0,
					name: pokemon.Name,
					variation: pokemon.Variation,
					generation: pokemon.Generation,
				});
			});
			setRows(newRows);
		}		
	}, [selectablePokemon, selectedRows]);

	const processRowUpdate = useCallback(
		(newRow: GridRowModel) => {
			const monChanging = selectablePokemon?.get(newRow.id);
			if (monChanging) {
				monChanging.cost = newRow.cost as number;
			}
			return newRow;
		},
		[selectablePokemon],
	);

	const handleProcessRowUpdateError = (params: any) => {
		console.log("error updating row cost");
	}

	if (pokemonMap === undefined || selectablePokemon === undefined) {
		return (<></>);
	}

	return (
		<Box sx={{ height: 800, width: 700 }}>
			<DataGrid
				rows={rows}
				columns={columnDefs} 
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={handleProcessRowUpdateError}
				experimentalFeatures={{ newEditingApi: true }}  />
		</Box>
	);
}