import * as React from 'react';
import { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import pokemonTypeWeakness from '../util/pokemonTypeWeakness';
import abilityImmunities from '../util/abilityImmunities';
import { Pokemon } from '../types/Pokemon';

const { isImmune } = abilityImmunities();
const { getWeaknessModifierForPokemon } = pokemonTypeWeakness();

const singleResist = {
    backgroundColor: '#70a34e',
    color: 'black'
};

const doubleResist = {
    backgroundColor: '#008080',
    color: 'black'
};

const singleWeakness = {
    backgroundColor: '#d4b04a',
    color: 'black'
};

const doubleWeakness = {
    backgroundColor: '#cb4c4e',
    color: 'white'
}

const immunity = {
    backgroundColor: '#9a78a1',
    color: 'black'
}

function getPokemonNameFromPieces(name: string, variation: string) {
    if (variation) {
        return name + " (" + variation + ")";
    }
    else {
        return name;
    }
}

function getPokemonName(pokemon: Pokemon) {
    return getPokemonNameFromPieces(pokemon.Name, pokemon.Variation);
}

function getOverallWeaknessesRow(allMons: Pokemon[]) {
    if (!allMons || allMons.length === 0) {
        return;
    }

    let overall = {
        Normal: 0,
        Fire: 0,
        Water: 0,
        Grass: 0,
        Electric: 0,
        Ice: 0,
        Fighting: 0,
        Poison: 0,
        Ground: 0,
        Flying: 0,
        Psychic: 0,
        Bug: 0,
        Rock: 0,
        Ghost: 0,
        Dragon: 0,
        Dark: 0,
        Steel: 0,
        Fairy: 0
    };

    for (const index in allMons) {
        const mon = allMons[index];
        if (!mon) {
            continue;
        }

        let weaknessMod = getWeaknessModifierForPokemon(mon);
        let type: keyof typeof weaknessMod; // Type is "Normal" | "Fire" | "Water"...
        for (type in weaknessMod) {
            if (isImmune(mon, type)) {
                overall[type]++;
            } else if (weaknessMod[type] < 1) {
                overall[type]++;
            }
            else if (weaknessMod[type] > 1) {
                overall[type]--;
            }
        }
    }

    let cells = [];
    cells.push(
        <TableCell key={'overallrow'}>
            Overall Balance
        </TableCell>
    )

    let type: keyof typeof overall; // Type is "Normal" | "Fire" | "Water"...
    for (type in overall) {
        let style = {};
        if (overall[type] === 1) {
            style = singleResist;
        } else if (overall[type] > 1) {
            style = doubleResist;
        } else if (overall[type] === -1) {
            style = singleWeakness;
        } else if (overall[type] < -1) {
            style = doubleWeakness;
        }
        cells.push
            (
                <TableCell key={'overall' + type} sx={style}>
                    {overall[type]}
                </TableCell>
            );
    }

    return (
        <TableRow key={'overall'} sx={{}}>
            {cells.map((cell) => cell)}
        </TableRow>
    );
}

function getRowForPokemon(mon: Pokemon, index: number) {
    if (!mon) {
        return;
    }

    let pokemonName = getPokemonName(mon);

    let weaknessMod = getWeaknessModifierForPokemon(mon);

    let cells = [];
    cells.push(
        <TableCell key={pokemonName + 'row' + index}>
            {pokemonName}
        </TableCell>
    )

    let type: keyof typeof weaknessMod;
    for (type in weaknessMod) {
        let style = {};
        switch (weaknessMod[type]) {
            case 0:
                style = immunity;
                break;
            case 0.25:
                style = doubleResist;
                break;
            case 0.5:
                style = singleResist;
                break;
            case 2:
                style = singleWeakness;
                break;
            case 4:
                style = doubleWeakness;
                break;
            default:
                style = {};
        }
        if (isImmune(mon, type)) {
            cells.push
                (
                    <TableCell key={pokemonName + type + index} sx={immunity}>
                        {"0*"}
                    </TableCell>
                );
        } else {
            cells.push
                (
                    <TableCell key={pokemonName + type + index} sx={style}>
                        {weaknessMod[type]}
                    </TableCell>
                );
        }
    }

    return (
        <TableRow key={pokemonName + index}>
            {cells.map((cell) => cell)}
        </TableRow>
    );
};

function getRowsForPokemon(pokemon: Pokemon[]) {

    if (!pokemon || !getWeaknessModifierForPokemon) {
        return [];
    }

    const sortedMon = [...pokemon].sort(function (a: Pokemon, b: Pokemon) { return b.Tier - a.Tier });
    const rows = sortedMon.map((mon: Pokemon, index: number) => getRowForPokemon(mon, index));

    let emptyCells = [];
    for (let i = 0; i < 19; i++) {
        emptyCells.push(
            <TableCell key={'emptycell' + i} sx={{ paddingTop: 4 }} />
        );
    }

    rows.push(
        <TableRow key={'emptyrow'} sx={{ padding: 10 }}>
            {emptyCells.map((cell) => cell)}
        </TableRow>
    );

    return rows;
}

type Props = {
    pokemon: Pokemon[],
    teamId?: number,
}

export default function TeamWeaknessTable({ pokemon, teamId }: Props) {
    const rows = getRowsForPokemon(pokemon);
    const overallRow = getOverallWeaknessesRow(pokemon);

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    Team Defensive Typing
                </Typography>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'header'}>
                                <TableCell key={'Pokemon'}>Pokemon</TableCell>
                                <TableCell key={'Normal'}>Normal</TableCell>
                                <TableCell key={'Fire'}>Fire</TableCell>
                                <TableCell key={'Water'}>Water</TableCell>
                                <TableCell key={'Grass'}>Grass</TableCell>
                                <TableCell key={'Electric'}>Electric</TableCell>
                                <TableCell key={'Ice'}>Ice</TableCell>
                                <TableCell key={'Fighting'}>Fighting</TableCell>
                                <TableCell key={'Poison'}>Poison</TableCell>
                                <TableCell key={'Ground'}>Ground</TableCell>
                                <TableCell key={'Flying'}>Flying</TableCell>
                                <TableCell key={'Psychic'}>Psychic</TableCell>
                                <TableCell key={'Bug'}>Bug</TableCell>
                                <TableCell key={'Rock'}>Rock</TableCell>
                                <TableCell key={'Ghost'}>Ghost</TableCell>
                                <TableCell key={'Dragon'}>Dragon</TableCell>
                                <TableCell key={'Dark'}>Dark</TableCell>
                                <TableCell key={'Steel'}>Steel</TableCell>
                                <TableCell key={'Fairy'}>Fairy</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                            <TableRow />
                            {overallRow}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}