import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StatLeader, { StatLeaderProps } from './StatLeader';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import { Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Pokemon } from '../../types/Pokemon';
import AllStatsTable from './AllStatsTable';

export interface StatLeader {
	Pokemon: Pokemon;
	PokemonUniqueId: number;
	SeasonId: number;
	Kills: number;
	Deaths: number;
	DamageTaken: number;
	DamageDealt: number;
	HazardDamageDealt: number;
	StatusDamageDealt: number;
	WeatherDamageDealt: number;
	DamageHealed: number;
	Wins: number;
	Losses: number;
	KillsRank: number;
	DeathsRank: number;
	DamageTakenRank: number;
	DamageDealtRank: number;
	HazardDamageDealtRank: number;
	StatusDamageDealtRank: number;
	WeatherDamageDealtRank: number;
	DamageHealedRank: number;
	WinsRank: number;
	LossesRank: number;
}

interface GetStatLeadersResponse {
	Leaders: StatLeader[];
}

async function getStatLeaders(seasonId: string, token: Token, setData: (r: GetStatLeadersResponse) => void) {
	fetch('/Pokemon/StatLeaders/' + seasonId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			alert("Unable to load at this time. Try again later");
			return;
		}

		setData(message);
	});
}

interface StatLeadersProps {
	seasonId: string;
}

export default function StatLeaders(props: StatLeadersProps) {
	const [data, setData] = useState<GetStatLeadersResponse | null>(null);
	const [leaders, setLeaders] = useState<StatLeaderProps[]>([]);

	const { token } = useToken();

	useEffect(() => {
		if (token) {
			getStatLeaders(props.seasonId, token, setData);
		}
	}, [token]);

	useEffect(() => {
		if (data) {
			// Build the leaders
			const newLeaders: StatLeaderProps[] = [];

			// Get the leader with kill rank 1
			const killLeader = data.Leaders.find(leader => leader.KillsRank === 1);
			const deathLeader = data.Leaders.find(leader => leader.DeathsRank === 1);
			const damageDealtLeader = data.Leaders.find(leader => leader.DamageDealtRank === 1);
			const hazardDamageDealtLeader = data.Leaders.find(leader => leader.HazardDamageDealtRank === 1);
			const statusDamageDealtLeader = data.Leaders.find(leader => leader.StatusDamageDealtRank === 1);
			const weatherDamageDealtLeader = data.Leaders.find(leader => leader.WeatherDamageDealtRank === 1);
			const damageHealedLeader = data.Leaders.find(leader => leader.DamageHealedRank === 1);
			const damageTakenLeader = data.Leaders.find(leader => leader.DamageTakenRank === 1);

			if (killLeader) {
				newLeaders.push({
					pokemon: killLeader.Pokemon,
					title: "Kills",
					value: killLeader.Kills,
				});
			}

			if (deathLeader) {
				newLeaders.push({
					pokemon: deathLeader.Pokemon,
					title: "Deaths",
					value: deathLeader.Deaths,
				});
			}

			if (damageHealedLeader) {
				newLeaders.push({
					pokemon: damageHealedLeader.Pokemon,
					title: "Damage Healed",
					value: damageHealedLeader.DamageHealed,
				});
			}

			if (damageTakenLeader) {
				newLeaders.push({
					pokemon: damageTakenLeader.Pokemon,
					title: "Damage Taken",
					value: damageTakenLeader.DamageTaken,
				});
			}

			if (damageDealtLeader) {
				newLeaders.push({
					pokemon: damageDealtLeader.Pokemon,
					title: "Damage Dealt",
					value: damageDealtLeader.DamageDealt,
				});
			}

			if (hazardDamageDealtLeader) {
				newLeaders.push({
					pokemon: hazardDamageDealtLeader.Pokemon,
					title: "Hazard Damage Dealt",
					value: hazardDamageDealtLeader.HazardDamageDealt,
				});
			}

			if (statusDamageDealtLeader) {
				newLeaders.push({
					pokemon: statusDamageDealtLeader.Pokemon,
					title: "Status Damage Dealt",
					value: statusDamageDealtLeader.StatusDamageDealt,
				});
			}

			if (weatherDamageDealtLeader) {
				newLeaders.push({
					pokemon: weatherDamageDealtLeader.Pokemon,
					title: "Weather Damage Dealt",
					value: weatherDamageDealtLeader.WeatherDamageDealt,
				});
			}

			setLeaders(newLeaders);
		}
	}, [data]);

	if (leaders.length === 0) {
		return null;
	}

	return (
		<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
			<Typography component="h2" variant="h6" gutterBottom>
				Stats
			</Typography>
			<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
				<Typography component="h2" variant="h6" gutterBottom>
					Leaderboard
				</Typography>
				<Box>
					<Grid container spacing={2}> {/* spacing controls gutter between items */}
						{leaders.map((leader, index) => (
							<Grid item xs={12} sm={6} md={3} key={index} sx={{ minWidth: 200 }}>
								<StatLeader {...leader} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Paper>
			{data?.Leaders && (
				<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
					<Typography component="h2" variant="h6" gutterBottom>
						All Stats
					</Typography>
					<Box>
						<AllStatsTable stats={data.Leaders} />
					</Box>
				</Paper>
			)}			
		</Paper>
		
	);
}
