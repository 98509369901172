import * as React from 'react';
import { useRef, useState } from 'react';
import './css/Home.css';
import logo from '../assets/logo-transparent-no-padding.png';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GroupsIcon from '@mui/icons-material/Groups';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { League } from '../types/League';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField } from '@mui/material';
import { Token } from '../types/Token';
import useToken from '../useToken';
import discordLogo from '../assets/discord_icon_clyde_white_RGB.svg';
import BuyCoffeeButton from './BuyCoffeeButton';

interface Props {
    history: any;
    window?: () => Window;
    drawerWidth: number;
    selectedLeague?: string;
    setSelectedLeague: (league: string) => void;
    leagues?: League[];
}

interface FeedbackRequest {
    Feedback: any;
}

async function sendFeedback(request: FeedbackRequest, token: Token) {
    fetch('/User/SubmitFeedback', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            return;
        }
    });
}

function logout(clearToken: any, history: any) {
    clearToken();
    history.push('/');
}

const discordInvite = 'https://discord.gg/3vDEvxYYZx';

function discordInviteClicked() {
    window.open(discordInvite, "_blank");
}

export default function LeaguesDrawer({ history, window, drawerWidth, selectedLeague, setSelectedLeague, leagues }: Props) {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const feedback = useRef<HTMLInputElement>(null);
    const { token, clearToken } = useToken();

    const handleFeedbackOpen = () => {
        setFeedbackOpen(true);
    };

    const handleFeedbackClose = () => {
        setFeedbackOpen(false);
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    const submitFeedback = async (e: any) => {
        e.preventDefault();
        setFeedbackOpen(false);
        if (feedback.current) {
            await sendFeedback({
                Feedback: feedback.current.value ?? ""
            }, token);
        }        
        setToastOpen(true);
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const leagueSection = (leagueToBuild: League) => {
        return (
            <ListItemButton key={"key|"+leagueToBuild.ExternalId} selected={leagueToBuild.ExternalId === selectedLeague} onClick={() => setSelectedLeague(leagueToBuild.ExternalId)}>
                <ListItemIcon>
                    <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary={leagueToBuild.Name} />
            </ListItemButton>
        );
    }

    const leagueSelection = () => {
        let output = [];        

        if (leagues && leagues.length > 0) {
            const leaguesSorted = [...leagues];
            leaguesSorted.sort((a, b) => a.Name.localeCompare(b.Name));

            for (let i = 0; i < leaguesSorted.length; i++) {
                output.push(leagueSection(leaguesSorted[i]));
            }
        }

        return output;
    }

    const drawerContents = (
        <Paper elevation={1} sx={{ height: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ my: 2 }}>
                    <Toolbar>
                        <img src={logo} alt="Logo" width="191" />
                    </Toolbar>
                </Box>
                <Divider />
                <List component="nav">
                    <ListItemButton onClick={() => history.push('/CreateLeague')}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="New league" />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    <ListItemButton onClick={discordInviteClicked}>
                        <ListItemIcon>
                            <img src={discordLogo} alt="Logo" width="25px" />
                        </ListItemIcon>
                        <ListItemText primary="Join our Discord for support and patch notes" />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    {/* Temporarily hide this since its not working properly
                    <ListItemButton onClick={handleFeedbackOpen}>
                        <ListItemIcon>
                            <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Send feedback" />
                    </ListItemButton>
                    <Divider sx={{ my: 1 }} />
                    */}
                    {leagueSelection()}
                </List>
                <Box sx={{ ml: "2px" }}>
                    <BuyCoffeeButton />
                </Box>                
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="text" color="secondary" onClick={() => logout(clearToken, history)}>Logout</Button>
            </Box>       
        </Paper>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<>
            <Box
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleDrawer}
                        sx={{ mb: 2, mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </Box>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawerContents}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawerContents}
                </Drawer>
            </Box>
            <Dialog open={feedbackOpen} onClose={handleFeedbackClose}>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        What feedback do you have for us?
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="feedback"
                        label="Feedback"
                        fullWidth
                        variant="standard"
                        inputRef={feedback}
                        defaultValue=""
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFeedbackClose}>Cancel</Button>
                    <Button onClick={submitFeedback}>Submit</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                message="Thank you for the feedback!"
            />
		</>
	);
}