import { Box, Button, Divider, Typography } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Team } from '../../../types/Team';
import { SelectedLeague } from '../../Home';
import ChecklistRow from './ChecklistRow';

interface PreDraftChecklistProps {
	activeTeams: Team[],
	selectedLeagueHomeObj: SelectedLeague,
	handleSettingsClickOpen: (defaultTab?: string) => void,
}

export default function PreDraftChecklist({ activeTeams, selectedLeagueHomeObj, handleSettingsClickOpen }: PreDraftChecklistProps) {
	const history = useHistory();

	const allTeamsAreFilled = activeTeams?.length > 0 && activeTeams.length === selectedLeagueHomeObj?.League.NumPlayers;
	const allTeamsHaveDraftPosition = allTeamsAreFilled && activeTeams?.every(team => (team.DraftPosition !== null && team.DraftPosition > 0));
	const hasDraftablePokemon = selectedLeagueHomeObj?.HasDraftablePokemonSettings;
	const hasDraftWebhook = selectedLeagueHomeObj?.HasDraftWebhook;
	const hasMatchWebhook = selectedLeagueHomeObj?.HasMatchWebhook;

	return (
		<Box>
			<Divider sx={{ my: 1 }} />
			<Typography variant="subtitle1" color="neutral">
				Complete these tasks before you can start the draft. You may need to refresh the page after updating these settings.
			</Typography>
			<ChecklistRow
				completed={allTeamsAreFilled}
				showText={!!activeTeams}
				text={`Fill all team slots. ${activeTeams.length}/${selectedLeagueHomeObj?.League.NumPlayers ?? 0} teams are filled. People can join using the invite link.`}
			/>
			<ChecklistRow
				completed={allTeamsHaveDraftPosition}
				showText={!!activeTeams}
				text="Assign a draft position to each team."
			>
				<Button variant="outlined" color="primary" sx={{ mt: 1 }} onClick={() => handleSettingsClickOpen("4")}>
					Update draft positions
				</Button>
			</ChecklistRow>
			<ChecklistRow
				completed={hasDraftablePokemon}
				showText={!!selectedLeagueHomeObj}
				text="Set which pokemon can be drafted"
			>
				<Button variant="outlined" color="primary" sx={{ mt: 1 }} onClick={() => history.push("/PokemonManager/" + selectedLeagueHomeObj.League.ExternalId)}>
					Update draftable pokemon
				</Button>
			</ChecklistRow>
			<Typography variant="subtitle1" color="neutral" sx={{ mt: 3 }}>
				Optional but recommended
			</Typography>
			<ChecklistRow
				completed={hasDraftWebhook}
				showText={!!activeTeams}
				text="Set a discord webhook for the draft. This will send a message to your discord channel whenever a pick is made and ping the user whose turn it is to pick. Your players will need to set their discord user id in their user settings if they want to be pinged (Click the gear next to the league name)."
			>
			</ChecklistRow>
			<ChecklistRow
				completed={hasMatchWebhook}
				showText={!!activeTeams}
				text="Set a discord webhook for match replays. This will send a message to your discord channel whenever a pokemon showdown replay is uploaded after the draft is over. Your players will also need to set their showdown username in their user settings (Click the gear next to the league name)"
			>
				<Button variant="outlined" color="primary" sx={{ mt: 1 }} onClick={() => handleSettingsClickOpen("3")}>
					Update league settings
				</Button>
			</ChecklistRow>
			<Divider sx={{ my: 1 }} />
		</Box>
		
	);
}