import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useState, useMemo } from 'react';
import { Pokemon } from '../types/Pokemon';
import { PokemonTeam } from '../types/PokemonTeam';
import { Token } from '../types/Token';
import useToken from '../useToken';
import getPokemonName from '../util/pokeFormatting';

interface EditTeamProps {
	team: PokemonTeam;
	seasonId: string;
}

// Get all pokemon
async function getAllPokemon(token: Token, setPokemon: (p: Pokemon[]) => void) {
	fetch('/Pokemon/GetAll', {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token

		})
	}).then(async response => {
		const message = await response.json();
		if (!response.ok) {
			return;
		}

		setPokemon(message);
	});
}

interface SaveRequest {
	TeamId: number;
	SeasonId: string;
	OldPokemonIds: number[];
	NewPokemonIds: number[];
}

// Save the team
async function saveTeam(team: SaveRequest, token: Token) {
	fetch('/Team/UpdatePokemonTeam', {
		method: 'POST',
		headers: new Headers({
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(team)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			return;
		}

		alert("Team saved successfully. Update the page to see the changes.");
	});
}

interface RemovePokemonRequest {
	SeasonId: string;
	TeamId: number;
	PokemonId: number;
}

// Remove pokemon from team
async function removePokemonFromTeam(request: RemovePokemonRequest, token: Token) {
	fetch('/Team/RemovePokemonFromTeam', {
		method: 'POST',
		headers: new Headers({
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			return;
		}
		alert("Pokemon removed successfully. Update the page to see the changes.");
	});
}

interface AddPokemonRequest {
	SeasonId: string;
	TeamId: number;
	PokemonId: number;
}

// Add pokemon to team
async function addPokemonToTeam(request: AddPokemonRequest, token: Token) {
	fetch('/Team/AddPokemonToTeam', {
		method: 'POST',
		headers: new Headers({
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			return;
		}
		alert("Pokemon added successfully. Update the page to see the changes.");
	});
}

function getMenuItemsForPokemon(pokemon: Pokemon[]) {
	const options = [];

	for (let i = 0; i < pokemon.length; i++) {
		options.push(<MenuItem value={pokemon[i].UniqueId}>{getPokemonName(pokemon[i])}</MenuItem>);
	}

	return options;
}

export default function EditTeam({ team, seasonId }: EditTeamProps) {
	const { token } = useToken();
	// Creates a deep copy of the team object
	const [teamCopy, setTeamCopy] = useState<PokemonTeam>(JSON.parse(JSON.stringify(team)));
	const [editMode, setEditMode] = useState<boolean>(false);
	const [pokemon, setPokemon] = useState<Pokemon[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [pokemonDropdowns, setPokemonDropdowns] = useState<any>([]);
	const [addPokemonModalOpen, setAddPokemonModalOpen] = useState(false);
	const [pokemonToAdd, setPokemonToAdd] = useState<number>();

	useEffect(() => {
		// Fetch all pokemon if edit button is clicked
		if (editMode && loading && pokemon.length == 0) {
			getAllPokemon(token, setPokemon);
		}
		
	}, [editMode, loading, pokemon]);

	useEffect(() => {
		if (editMode && loading && pokemon?.length > 0) {
			setPokemonDropdowns(getMenuItemsForPokemon(pokemon));
			setLoading(false);
		}
	}, [editMode, loading, pokemon]);

	useEffect(() => {
		setTeamCopy(JSON.parse(JSON.stringify(team)));
	}, [team]);

	const saveClicked = () => {
		// If there are pokemon in teamCopy with duplicate UniqueIds, alert the user
		const uniqueIds = teamCopy.Pokemon.map(p => p.UniqueId);
		if (uniqueIds.length !== new Set(uniqueIds).size) {
			alert("There are duplicate pokemon in the team. Please remove the duplicates before saving.");
			return;
		}

		// Save the team
		saveTeam({
			TeamId: teamCopy.TeamId,
			SeasonId: seasonId,
			OldPokemonIds: team.Pokemon.map(p => p.UniqueId),
			NewPokemonIds: teamCopy.Pokemon.map(p => p.UniqueId)
		}, token);
		setEditMode(false);
	};

	const removePokemonClicked = (pokemonUniqueId: number) => {
		// If this is the last pokemon on the team, alert the user
		if (teamCopy.Pokemon.length == 1) {
			alert("You cannot remove the last pokemon from the team.");
			return;
		}

		// Remove the pokemon from the team
		removePokemonFromTeam({
			SeasonId: seasonId,
			TeamId: teamCopy.TeamId,
			PokemonId: pokemonUniqueId
		}, token);
	}

	const submitAddPokemonToTeam = async (e: any) => {
		e.preventDefault();
		setAddPokemonModalOpen(false);

		// If this pokemon is already on the team, alert the user
		if (teamCopy.Pokemon.find(p => p.UniqueId == pokemonToAdd)) {
			alert("This pokemon is already on the team.");
			return;
		}

		if (pokemonToAdd) {
			await addPokemonToTeam({
				SeasonId: seasonId,
				TeamId: teamCopy.TeamId,
				PokemonId: pokemonToAdd
			}, token);
		}
		setAddPokemonModalOpen(false);
	}

	const updatePokemonOnTeam = (value: any, index: number) => {
		// Update the pokemon on the team
		teamCopy.Pokemon[index].UniqueId = value.target.value;
	};

	const pokemonToAddUpdated = (value: any) => {
		setPokemonToAdd(value.target.value);
	};


	return (
		<Box>
			{!editMode && (
				<Box sx={{ mt: 2, mb: 2 }}>
					<Button
						variant="contained"
						onClick={() => setEditMode(true)}
					>
						Edit
					</Button>
				</Box>
			)}
			{editMode && (
				<Box>
					<Box sx={{ mt: 2, mb: 2 }}>
						<Button
							variant="contained"
							onClick={saveClicked}
						>
							Save edits
						</Button>
						<Button
							variant="contained"
							onClick={() => setAddPokemonModalOpen(true)}
							sx={{ ml: 2 }}
						>
							Add New Pokemon
						</Button>
						<Button
							variant="contained"
							onClick={() => setEditMode(false)}
							sx={{ ml: 2 }}
						>
							Cancel
						</Button>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						{team.Pokemon.map((p, i) => {
							return (
								<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
									<FormControl sx={{ mt: 2, width: "100%" }}>
										<InputLabel id={"select-label-" + i}>Pokemon</InputLabel>								
										<Select 
										defaultValue={p.UniqueId}
										labelId={"select-label-" + i}
										onChange={(value) => updatePokemonOnTeam(value, i)}
										>
											{pokemonDropdowns}
										</Select>			
									</FormControl>
									<Button
										variant="text"
										onClick={() => {
											removePokemonClicked(p.UniqueId);
										}}
										color="error"
										sx={{ ml: 2, mt: 2 }}
									>
										Remove
									</Button>
								</Box>
							);
						})}
					</Box>
					<Dialog open={addPokemonModalOpen} onClose={() => setAddPokemonModalOpen(false)}>
						<DialogTitle>Add Pokemon to team</DialogTitle>
						<DialogContent>
							<FormControl sx={{ mt: 2, width: "100%" }}>
								<InputLabel id={"addPokemonModalSelect"}>Pokemon</InputLabel>
								<Select
									labelId={"addPokemonModalSelect"}
									onChange={(value) => pokemonToAddUpdated(value)}
								>
									{pokemonDropdowns}
								</Select>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setAddPokemonModalOpen(false)}>Cancel</Button>
							<Button onClick={submitAddPokemonToTeam}>Submit</Button>
						</DialogActions>
					</Dialog>
				</Box>
			)}
		</Box>
	);
}