import { Box } from "@mui/material";
import React from "react";

export function Divider({ onDrag }: any) {
    const handleMouseDown = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        window.addEventListener('mousemove', onDrag);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseUp = () => {
        window.removeEventListener('mousemove', onDrag);
        window.removeEventListener('mouseup', handleMouseUp);
    };

    return <Box className="divider" onMouseDown={handleMouseDown} sx={{ height: '10px', cursor: 'row-resize' }} />;
}
