import * as React from 'react';
import Box from '@mui/material/Box';
import { RequestStatus } from '../types/RequestStatus';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
    status: RequestStatus
}

export default function RequestStatusIndicator({ status }: Props) {

    return (
        <Box>
            {status === RequestStatus.Loading && (
                <CircularProgress />
            )}
            {status === RequestStatus.Successful && (
                <CheckCircleIcon color="success" />
            )}
            {status === RequestStatus.Failed && (
                <ErrorIcon color="error" />
            )}
        </Box>
    );
}

