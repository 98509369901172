import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import * as React from 'react';


interface Props {
    isOpen: boolean;
    onDialogClose: () => void;
    content: any;
}

export default function GenericDialog({ isOpen, onDialogClose, content }: Props) {
    return (
        <Dialog open={isOpen} onClose={onDialogClose}>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

