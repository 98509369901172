import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { RequestStatus } from '../../types/RequestStatus';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import RequestStatusIndicator from '../RequestStatusIndicator';
import CircularProgress from '@mui/material/CircularProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Alert, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Team } from '../../types/Team';
import { useHistory } from "react-router-dom";

interface DraftSettings {
    Teams: Team[],
    NumPlayers: number,
    DraftRounds: number,
    DraftType: "Snake" | "Linear",
    CostEnabled: boolean,
    Money: number,
}

async function getDraftSettingsRequest(leagueId: string, seasonId: string, token: Token, setLoading: (b: boolean) => void, setDraftSettings: (d: DraftSettings) => void) {
    fetch('/Draft/Settings/' + leagueId + "/" + seasonId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message: DraftSettings = await response.json();
        setLoading(false);

        if (!response.ok) {
            return;
        }

        setDraftSettings(message);
    });
}

interface SendUpdateRequest {
    LeagueId: string,
    SeasonId: string,
    TeamsToSet: { Id: number; DraftPosition: number; }[],
    DraftRounds: number,
    DraftType: "Snake" | "Linear",
    CostEnabled: boolean,
    Money: number,
}

async function sendUpdateRequest(request: SendUpdateRequest, token: Token, setUpdateRequestStatus: (s: RequestStatus) => void) {
    fetch('/Draft/Settings', {
        method: 'PUT',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            setUpdateRequestStatus(RequestStatus.Failed);
            return;
        }

        setUpdateRequestStatus(RequestStatus.Successful);
    });
}

function getMenuItemsForTeams(teams: Team[]) {
    const options = [];

    for (let i = 0; i < teams?.length; i++) {
        options.push(<MenuItem value={teams[i].Id}>{teams[i].OwnerDisplayName}</MenuItem>);
    }

    return options;
}

interface Props {
    leagueId: string,
    seasonId?: string,
    teams?: Team[],
}

export default function DraftSettingsTab({ leagueId, seasonId }: Props) {
    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [draftSettings, setDraftSettings] = useState<DraftSettings>();
    const [updateRequestStatus, setUpdateRequestStatus] = useState<RequestStatus>(RequestStatus.None);
    const history = useHistory();

    useEffect(() => {
        if (token && loading && leagueId && seasonId) {
            getDraftSettingsRequest(leagueId, seasonId, token, setLoading, setDraftSettings);
        }
    }, [token, loading, leagueId, seasonId]);

    const teamSelectOptions = useMemo(() => draftSettings ? getMenuItemsForTeams(draftSettings.Teams) : [], [draftSettings]);

    const updateOrderForTeam = (event: any, newOrder: number) => {
        if (draftSettings) {
            draftSettings.Teams.find(t => t.Id === event.target.value)!.DraftPosition = newOrder;
        }
    };

    const teamDropdowns = () => {
        let dropdowns = [];

        if (draftSettings) {
            for (let i = 0; i < draftSettings.NumPlayers; i++) {
                const selectedTeam = draftSettings.Teams.find(t => t.DraftPosition === i + 1)?.Id;
                const label = i + 1;
                dropdowns.push(
                    <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            fullWidth
                            defaultValue={selectedTeam ?? null}
                            label={label}
                            onChange={(value) => updateOrderForTeam(value, i + 1)}
                        >
                            {teamSelectOptions}
                        </Select>
                    </FormControl>
                );
            }
        }

        return dropdowns;
    }

    const submitDraftSettingsUpdate = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.target);

        if (!draftSettings) {
            return;
        }

        // Fail if any teams have the same draft position, or if any teams have an invalid draft position
        let uniquePositions = new Set<number>();
        const teamsToSet: { Id: number; DraftPosition: number; }[] = [];
        for (var team of draftSettings.Teams) {
            teamsToSet.push({ Id: team.Id, DraftPosition: team.DraftPosition });
            if (uniquePositions.has(team.DraftPosition)
                || team.DraftPosition <= 0
                || team.DraftPosition > draftSettings.NumPlayers) {
                setUpdateRequestStatus(RequestStatus.Failed);
                return;
            }
            uniquePositions.add(team.DraftPosition);
        }

        // Fail if the number of teams to set is not equal to the number of players
        if (teamsToSet.length !== draftSettings.NumPlayers) {
            setUpdateRequestStatus(RequestStatus.Failed);
            return;
        }

        await sendUpdateRequest({
            LeagueId: leagueId,
            SeasonId: seasonId ?? "",
            TeamsToSet: teamsToSet,
            DraftRounds: Number(data.get('rounds')),
            DraftType: data.get('draftType') as "Snake" | "Linear",
            CostEnabled: data.get('costEnabled') === 'on',
            Money: data.get('costEnabled') === 'on' ? Number(data.get('money')) : 0,
        }, token, setUpdateRequestStatus);
    }

    return (
        <>
            <Box sx={{ mt: 1, height: '650px' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">
                            Draft Settings
                        </Typography>
                        <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => window.open("https://draftleague.net/PokemonManager/" + leagueId, "_blank")}>
                            Update draftable pokemon
                            <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                        </Button>
                    </Box>                    
                    {loading && (
                        <Box>
                            <CircularProgress />
                        </Box>
                    )}
                    {draftSettings && !loading && (
                        <>
                            <Alert severity="info" sx={{ mt: 2 }}>You have to click "Update Draft Settings" at the bottom to save your changes.</Alert>                                                    
                            <Box sx={{ flexDirection: 'column', mt: 2, pb: 2 }} component="form" onSubmit={submitDraftSettingsUpdate}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="rounds"
                                    label="Draft Rounds"
                                    name="rounds"
                                    type="number"
                                    defaultValue={draftSettings.DraftRounds}
                                    inputProps={{ min: 1, max: 20 }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="dropdown-label">Draft Type</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="draftType"
                                        defaultValue={draftSettings.DraftType.charAt(0).toUpperCase() + draftSettings.DraftType.slice(1)}
                                        name="draftType"
                                    >
                                        <MenuItem value="Snake">Snake</MenuItem>
                                        <MenuItem value="Linear">Linear</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="number"
                                    margin="normal"
                                    fullWidth
                                    label="Money per player"
                                    name="money"
                                    id="money"
                                    defaultValue={draftSettings.Money}
                                    inputProps={{ min: 0, max: 2000000000 }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={draftSettings.CostEnabled}
                                            name="costEnabled"
                                            id="costEnabled"
                                        />
                                    }
                                    label="Pokemon cost money to draft"
                                />
                                <Divider sx={{ my: 1 }} />
                                <Typography variant="subtitle1" gutterBottom>
                                    Draft order
                                </Typography>
                                {teamDropdowns()}      
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'flex', mt: 3, mb: 3 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Update draft settings
                                    </Button>
                                    <Box sx={{ mt: '6px', ml: '6px' }} >
                                        <RequestStatusIndicator status={updateRequestStatus} />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}

