import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Box, DialogContentText, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Pokemon } from '../../types/Pokemon';
import getPokemonName from '../../util/pokeFormatting';

interface Props {
    submitPick: (pickNum: number, primary: Pokemon) => Promise<void>;
    isOpen: boolean;
    onDialogClose: () => void;
    pokemon: Pokemon[];
}

// TODO: Move to hook - is reused in other places
function getMenuItemsForPokemon(pokemon: Pokemon[]) {
    const options = [];

    for (let i = 0; i < pokemon.length; i++) {
        options.push(<MenuItem value={pokemon[i].UniqueId}>{getPokemonName(pokemon[i])}</MenuItem>);
    }

    return options;
}

export default function ForcePickDialog({ submitPick, isOpen, onDialogClose, pokemon }: Props) {
    const [pokemonDropdowns, setPokemonDropdowns] = useState<any>([]);
    const [pokemonToPick, setPokemonToPick] = useState<number>();
    const [pickNum, setPickNum] = useState<number>(0);
    
    useEffect(() => {
        if (pokemon?.length > 0) {
            setPokemonDropdowns(getMenuItemsForPokemon(pokemon));
        }
    }, [pokemon]);

    const pokemonToAddUpdated = (value: any) => {
        setPokemonToPick(value.target.value);
    };

    const submitPickClicked = () => {
        const selectedMon = pokemon.find(p => p.UniqueId === pokemonToPick);

        if (!selectedMon || pickNum < 1) {
            return;
        }

        submitPick(pickNum, selectedMon);
    };

    return (
        <Dialog open={isOpen} onClose={onDialogClose}>
            <DialogTitle>Force pick</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <DialogContentText>
                        Use your admin powers to force set a pick
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Pick number"
                        type="number"
                        onChange={(value: any) => setPickNum(value.target.value)}
                    />
                    <FormControl sx={{ mt: 2, width: "100%" }}>
                        <InputLabel id={"addPokemonModalSelect"}>Pokemon</InputLabel>
                        <Select
                            labelId={"addPokemonModalSelect"}
                            onChange={(value) => pokemonToAddUpdated(value)}
                        >
                            {pokemonDropdowns}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={submitPickClicked}
                        sx={{ mt: 2 }}
                    >
                        Submit
                    </Button>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

