import * as React from 'react';
import { useState } from 'react';
import './css/Login.css';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ForgotPassword from './PasswordReset/ForgotPassword';
import { Tooltip } from '@mui/material';

interface loginRequest {
    Email: any,
    Password: any,
}

async function loginUser(credentials: loginRequest, setToken: any) {
    fetch('/Login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }
        setToken(message);
    });
}

interface registerRequest {
    User: any,
    DisplayName: any,
    DiscordUsername: any,
    ShowdownName: any,
    Email: any,
    Password: any,
}

async function register(credentials: registerRequest, setToken: any) {
    fetch('/Login/Register/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }
        setToken(message);
    });
}

type Props = {
    setToken: any,
}

export default function Login({ setToken }: Props) {
    const [loginMode, setLoginMode] = useState("login");

    const submitLogin = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        await loginUser({
            Email: data.get('email'),
            Password: data.get('password'),
        }, setToken);
    }

    const submitRegister = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if (data.get('password') !== data.get('passwordTwo')) {
            alert("Passwords don't match");
            return;
        }
        if (data.get('email') !== data.get('emailTwo')) {
            alert("Emails don't match");
            return;
        }
        await register({
            User: data.get('username'),
            DisplayName: data.get('displayName'),
            DiscordUsername: data.get('discordid') ?? "",
            ShowdownName: data.get('showdownName') ?? "",
            Email: data.get('email'),
            Password: data.get('password'),
        }, setToken);
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {loginMode === "forget" && (
                        <ForgotPassword setLoginMode={setLoginMode} />
                    )}
                    {loginMode === "register" && (
                        <>
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                            <Box component="form" noValidate onSubmit={submitRegister} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="username"
                                            label="Username"
                                            name="username"
                                            autoComplete="username"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="displayName"
                                            label="Display name"
                                            name="displayName"
                                            autoComplete="displayName"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="emailTwo"
                                            label="Re-enter Email Address"
                                            name="emailTwo"
                                            autoComplete="emailTwo"
                                            helperText="We only use your email to send 'Forgot Password' emails. No marketing, no spam, no bs"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="passwordTwo"
                                            label="Re-enter Password"
                                            type="password"
                                            id="passwordTwo"
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="discordid"
                                            label="Discord User Id"
                                            name="discordid"
                                            autoComplete="discordid"
                                            helperText="Optional. Used to ping you when it's your turn to draft"
                                        />
                                        <Tooltip title="Your Discord User Id is not your username. It is a 17 or 18 digit number that can be found in your Discord settings. Click me for more information. If you don't know what this or don't use Discord you can leave this blank.">
                                            <InfoOutlinedIcon sx={{ ml: 0.5, mt: "15px" }} onClick={() => window.open("https://tinyurl.com/yc3jft2t", "_blank", "noopener,noreferrer")} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="showdownName"
                                            label="Pokemon Showdown Username"
                                            name="showdownName"
                                            autoComplete="discordid"
                                            helperText="Optional. Used to track your matches"
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            fullWidth
                                            onClick={() => setLoginMode("login")}
                                            variant="text"
                                        >
                                            Already have an account? Sign in
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {loginMode === "login" && (
                        <>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box component="form" onSubmit={submitLogin} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link variant="body2" onClick={() => setLoginMode("forget")}>
                                            Forgot password?
                                        </Link>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            type="button"
                                            fullWidth
                                            onClick={() => setLoginMode("register")}
                                            variant="text"
                                        >
                                            Sign Up
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}                    
                </Box>
            </Container>
        </>
    );
}