import { Box, Button, IconButton, Paper, Popover, Typography } from '@mui/material';
import React from 'react';
import { Team } from '../../../types/Team';
import SettingsIcon from '@mui/icons-material/Settings';
import useToken from '../../../useToken';
import { SelectedLeague } from '../../Home';
import { Token } from '../../../types/Token';
import { useHistory } from 'react-router-dom';
import PreDraftChecklist from './PreDraftChecklist';

interface DraftTabProps {
	inviteUrl: string,
	activeTeams: Team[],
	handleSettingsClickOpen: (defaultTab?: string) => void,
	selectedLeague: string,
	selectedLeagueHomeObj: SelectedLeague,
}

interface AddTeamForSelfRequest {
	LeagueId: string,
	SeasonId: string,
}

async function addTeamForSelf(request: AddTeamForSelfRequest, token: Token) {
	fetch('/League/AddTeam', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			alert(message);
			return;
		}
		window.location.reload();
	});
}

async function startDraft(token: Token, leagueId: string, seasonId: string, history: any) {
	fetch('/Draft/Start/' + leagueId, {
		method: 'POST',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			alert(message);
			return;
		}
		history.push("/Draft/" + message);
	});
}

export default function DraftTab({ inviteUrl, activeTeams, handleSettingsClickOpen, selectedLeague, selectedLeagueHomeObj }: DraftTabProps) {
	const { token } = useToken();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
		navigator.clipboard.writeText(inviteUrl);
		setAnchorEl(event.currentTarget);
	};

	const handleCopyClose = () => {
		setAnchorEl(null);
	};

	const addSelfTeam = () => {
		addTeamForSelf({
			LeagueId: selectedLeague ? selectedLeague : "",
			SeasonId: selectedLeagueHomeObj?.SeasonId ?? "",
		}, token);
	};

	const startDraftClicked = () => {
		if (selectedLeague && selectedLeagueHomeObj?.SeasonId) {
			startDraft(token, selectedLeague, selectedLeagueHomeObj?.SeasonId, history);
		}
	};

	const copyPopoverOpen = Boolean(anchorEl);

	return (
		<Box>
			{inviteUrl && (
				<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
					<Box>
						<Typography variant="h6">
							Invite friends to join the league
						</Typography>
						<Typography variant="body2" color="neutral">
							Copy the link and share with your friends. When they go to the link, they will automatically join the league and have a team created for them.
						</Typography>
						<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", justifyContent: "space-between" }}>
							<Typography variant="body1" sx={{ mt: 1 }}>
								{inviteUrl}
							</Typography>
							<Button variant="contained" onClick={handleCopy}>
								Copy
							</Button>
							<Popover
								open={copyPopoverOpen}
								anchorEl={anchorEl}
								onClose={handleCopyClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Typography sx={{ p: 2 }}>Copied!</Typography>
							</Popover>
						</Paper>
					</Box>
				</Paper>
			)}			
			<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Typography variant="h6">
						Draftboard
					</Typography>
					<IconButton color="neutral" aria-label="open settings" onClick={() => handleSettingsClickOpen("4")}>
						<SettingsIcon />
					</IconButton>
				</Box>
				{(selectedLeagueHomeObj?.Draft == null || (!selectedLeagueHomeObj?.Draft.Active && !selectedLeagueHomeObj?.Draft.Completed)) && (
					<>
						{selectedLeagueHomeObj.IsLeagueOwner && (
							<>
								<PreDraftChecklist activeTeams={activeTeams} selectedLeagueHomeObj={selectedLeagueHomeObj} handleSettingsClickOpen={handleSettingsClickOpen} />
								<Button disabled={!selectedLeagueHomeObj?.CanStartDraft} variant="contained" onClick={startDraftClicked} sx={{ width: "fit-content", mt: 1 }}>
									Start Draft
								</Button>
							</>							
						)}
						{!selectedLeagueHomeObj.IsLeagueOwner && (
							<Typography variant="body2" color="neutral">
								Once the draft has started you'll be able to join the draft here.
							</Typography>
						)}
					</>
				)}

				{selectedLeagueHomeObj?.Draft?.Active && (
					<Button variant="contained" sx={{ width: "fit-content" }} onClick={() => history.push("/Draft/" + selectedLeagueHomeObj.Draft.ExternalId)}>
						Join draft
					</Button>
				)}

				{selectedLeagueHomeObj?.Draft?.Completed && (
					<Button variant="contained" sx={{ width: "fit-content" }} onClick={() => history.push("/Draft/" + selectedLeagueHomeObj.Draft.ExternalId)}>
						View draft results
					</Button>
				)}
			</Paper>
			<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
				<Box>
					<>
						<Typography variant="h6" sx={{ mb: 2 }}>
							{"Teams (" + activeTeams.length + "/" + selectedLeagueHomeObj?.League.NumPlayers + ")" }
						</Typography>
						{selectedLeagueHomeObj?.CanAddTeamForSelf && (
							<Button variant="contained" onClick={addSelfTeam}>
								Add team for self
							</Button>
						)}
						{activeTeams.map((team, index) => {
							const uniqueKey = "team-" + index;
							return (
								<Box key={uniqueKey} sx={{ mt: 2}}>
									<Typography variant="body1">
										{team.OwnerDisplayName}
									</Typography>
									<Typography variant="caption">
										Draft position {team.DraftPosition == 0 ? " not set" : "#" + team.DraftPosition}
									</Typography>
								</Box>								
							)							
						})}
					</>					
				</Box>
			</Paper>
		</Box>
	);
}