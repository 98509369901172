import { Box, Typography } from '@mui/material';
import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface ChecklistRowProps {
	completed: boolean,
	text: string,
	showText: boolean,
	children?: React.ReactNode
}

export default function ChecklistRow({ completed, text, showText, children }: ChecklistRowProps) {

	return (
		<Box sx={{ mt: 1 }}>
			<Box alignItems="center" sx={{ display: 'flex' }}>
				{completed ? <CheckBoxIcon color="success" /> : <CheckBoxOutlineBlankIcon />}
				{showText && (
					<Typography variant="body2" color="neutral" sx={{ ml: 1 }}>
						{text}
					</Typography>
				)}
			</Box>
			<Box sx={{ ml: 4 }}>
				{children}
			</Box>			
		</Box>
		
	);
}