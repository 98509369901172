import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Token } from '../../types/Token';
import { GetDraftablePokemonSettingsResponse, SelectablePokemon, SelectablePokemonRow, UpdateDraftablePokemonRequest } from './types';
import useToken from '../../useToken';
import { Alert, Box, Button, CircularProgress, CssBaseline, IconButton, Input, InputLabel, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Pokemon } from '../../types/Pokemon';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CostTable from './CostTable';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';

async function getPokemonList(leagueId: string, token: Token, setData: (r: GetDraftablePokemonSettingsResponse) => void) {
	fetch('/League/DraftablePokemon/' + leagueId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			alert("Unable to load at this time. Try again later");
			return;
		}

		setData(message);
	});
}

async function updateSettings(request: UpdateDraftablePokemonRequest, token: Token) {
	fetch('/League/SetDraftablePokemon/', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			alert(message);
			return;
		}

		alert("Settings updated");
	});
}

function constructSelectablePokemonRows(settings: GetDraftablePokemonSettingsResponse) {
	// Construct a map of pokemonId to SelectablePokemon
	let selectablePokemonSettings = settings?.SelectablePokemonJson ? JSON.parse(settings.SelectablePokemonJson) as SelectablePokemon[] : [];
	let pokemonSettingsMap = new Map<number, SelectablePokemon>();
	selectablePokemonSettings.forEach(ps => pokemonSettingsMap.set(ps.uniqueId, ps));

	// Construct rows for each pokemon
	const selectablePokemonMap: Map<number, SelectablePokemon> = new Map<number, SelectablePokemon>();
	settings.Pokemon.sort((a, b) => a.Id - b.Id);
	settings.Pokemon.forEach(p => {
		let pokemonSettings = pokemonSettingsMap.get(p.UniqueId);
		selectablePokemonMap.set(p.UniqueId, {
			selected: pokemonSettings?.selected ?? true,
			cost: pokemonSettings?.cost ?? 0,
			pokemonId: p.Id,
			uniqueId: p.UniqueId
		});
	});

	return selectablePokemonMap;
}

const CSVLinkRef = React.forwardRef((props, ref) => (
	<CSVLink {...props} innerRef={ref} />
));

export default function PokemonManager({ history }: any) {
	const { token } = useToken();
	let { leagueIdStr } = useParams<{ leagueIdStr: string }>();

	const [data, setData] = useState<GetDraftablePokemonSettingsResponse>();
	const [loading, setLoading] = useState<boolean>(true);
	const [selectablePokemon, setSelectablePokemon] = useState<Map<number, SelectablePokemon>>();
	const [pokemonMap, setPokemonMap] = useState<Map<number, Pokemon>>();
	const [rows, setRows] = useState<SelectablePokemonRow[]>([]);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const fileInputRef = useRef<HTMLInputElement>(null); // For CSV import

	// Load data from server
	useEffect(() => {
		if (loading && !data) {
			getPokemonList(leagueIdStr, token, setData);
		}
	}, [loading, leagueIdStr, token, data, setData]);

	// Turn Pokemon array into a map for building the table
	useEffect(() => {
		if (loading && data?.Pokemon && !pokemonMap) {
			let pokemonMap = new Map<number, Pokemon>();
			data.Pokemon.forEach(p => pokemonMap.set(p.UniqueId, p));
			setPokemonMap(pokemonMap);
		}
	}, [loading, data, pokemonMap]);

	// Extract settings from data JSON + build if empty
	useEffect(() => {
		if (loading && data && !selectablePokemon && pokemonMap) {
			setSelectablePokemon(constructSelectablePokemonRows(data));
			setLoading(false);
		}
	}, [loading, data, selectablePokemon, pokemonMap]);

	// Build rows from settings
	useEffect(() => {
		if (selectablePokemon) {
			const newRows: SelectablePokemonRow[] = [];
			const newSelectedRows: number[] = [];
			selectablePokemon.forEach(sp => {
				const pokemon = pokemonMap?.get(sp.uniqueId);
				newRows.push({
					id: sp.uniqueId,
					pokemonId: sp.pokemonId,
					name: pokemon?.Name ?? "",
					variation: pokemon?.Variation ?? "",
					generation: pokemon?.Generation ?? -1,
				});
				if (sp.selected) {
					newSelectedRows.push(sp.uniqueId);
				}
			});
			setRows(newRows);
			setSelectedRows(newSelectedRows);
		}
	}, [selectablePokemon, pokemonMap]);

	const columns: GridColDef[] = [
		{ field: 'pokemonId', headerName: 'Pokemon ID', width: 100 },
		{ field: 'name', headerName: 'Name', width: 150 },
		{ field: 'variation', headerName: 'Variation', width: 150 },
		{ field: 'generation', headerName: 'Generation', width: 100 },
	];

	const toggleGeneration = (generation: number, value: boolean, setMethod: any) => {
		const newSelectedRows = new Set(selectedRows);
		rows.forEach(r => {
			if (r.generation == generation) {
				if (value) {
					newSelectedRows.add(r.id);
				} else {
					newSelectedRows.delete(r.id);
				}
			}
		});

		setMethod(Array.from(newSelectedRows));
	};

	const saveButtonClick = () => {
		const selectedSet = new Set(selectedRows);
		const selectablePokemonSettings: SelectablePokemon[] = [];
		selectablePokemon?.forEach(sp => {
			selectablePokemonSettings.push({
                selected: selectedSet.has(sp.uniqueId),
                cost: sp.cost,
				uniqueId: sp.uniqueId,
				pokemonId: sp.pokemonId
            });
		});

		// TODO: If successful, push to the league page
		updateSettings({
			LeagueId: leagueIdStr,
			CostEnabled: false,
			SettingsJson: JSON.stringify(selectablePokemonSettings),
        }, token)
	}

	const dataForExport = () => {
		const selectedSet = new Set(selectedRows);
		const selectablePokemonSettings: SelectablePokemon[] = [];
		selectablePokemon?.forEach(sp => {
			selectablePokemonSettings.push({
				selected: selectedSet.has(sp.uniqueId),
				cost: sp.cost,
				uniqueId: sp.uniqueId,
				pokemonId: sp.pokemonId
			});
		});

		const data = selectablePokemonSettings.map(sp => {
			const pokemon = pokemonMap?.get(sp.uniqueId);
			return {
				id: sp.uniqueId,
				name: pokemon?.Name ?? "",
				variation: pokemon?.Variation ?? "",
				generation: pokemon?.Generation ?? -1,
				selected: sp.selected,
				tier: sp.cost
			};
		});

		return data;
	}

	const exportHeaders = [
		{ label: 'ID', key: 'id' },
		{ label: 'Name', key: 'name' },
		{ label: 'Variation', key: 'variation' },
		{ label: 'Generation', key: 'generation' },
		{ label: 'Draftable', key: 'selected' },
		{ label: 'Tier', key: 'tier' },
	];

	const handleFileImport = (event: any) => {
		Papa.parse(event.target.files[0], {
			header: true,
			complete: function (results: any) {
				// Filter out empty rows (where all values are empty or undefined)
				const filteredData = results.data.filter((row: any) => {
					return Object.values(row).some(value => value !== "" && value !== undefined);
				});

				// Check that all unique pokemon exist in filteredData and that the ID, Name and Variation match
				// If they do, then update the selectable pokemon settings
				let issues: string[] = [];
				let rowIndex = 2;

				// Create a Set of Pokemon IDs from the filtered data
				const importedPokemonIds = new Set(filteredData.map((row: any) => +row.ID));

				// Find the missing Pokemon IDs
				const missingPokemon = Array.from(pokemonMap!.keys()).filter(
					(id) => !importedPokemonIds.has(id)
				);
				missingPokemon.forEach((id) => {
					const pokemon = pokemonMap?.get(id);
					issues.push(`Missing Pokemon: ${id} ${pokemon?.Name} ${pokemon?.Variation}`);
				});

				// Find the extra Pokemon IDs or Pokemon IDs with incorrect Name or Variation
				filteredData.forEach((row: any) => {
					const pokemon = pokemonMap?.get(+row.ID);
					if (pokemon) {
						if (pokemon.Name != row.Name || pokemon.Variation != row.Variation) {
							issues.push(`Row ${rowIndex}: Name or variation mismatch: ${row.Name +
								" " +
								row.Variation} does not equal the expected ${pokemon.Name +
								" " +
								pokemon.Variation}`);
						}

					} else {
						issues.push(`Row ${rowIndex}: Pokemon ID not recognized: ${row.ID}`);
					}
					rowIndex++;
				});

				if (issues.length > 0) {
					alert("Issues found with import: \n" + issues.join("\n\n"));
				}
				else if (data) {
					// Build new selectable pokemon settings
					const newJson = filteredData.map((row: any) => {
						const pokemon = pokemonMap?.get(+row.ID);
						return {
							selected: row.Draftable?.toLowerCase() == "true",
							cost: +row.Tier,
							uniqueId: +row.ID,
							pokemonId: pokemon?.Id ?? -1
						};
					});

					// Set new data in the same format we would've received from the API
					const newData = {
						CostEnabled: data?.CostEnabled ?? true,
						SelectablePokemonJson: JSON.stringify(newJson),
						Pokemon: data?.Pokemon ?? [],
					};
					setData(newData);
					setSelectablePokemon(constructSelectablePokemonRows(newData));

					alert("Import successful. The UI has been updated with the new data. Click 'Save' to save!");
				}
			}
		});

		// Clear the input value after processing to allow re-selection
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<Box>
			<Box>
				<IconButton color="primary" aria-label="Go back" onClick={() => history.push('/home/' + leagueIdStr)}>
					<ArrowBackIcon />
				</IconButton>
			</Box>
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography variant="h4" marginLeft={2}>
					Draftable Pokemon
				</Typography>
				<Alert severity="info" sx={{ mt: 2 }}>You have to click "Save" at the top-right save your changes. You must do this at least once before starting a draft. Join our discord channel for community CSVs</Alert> 
			</Box>			
			<Box display="flex">
				<Box sx={{
					display: 'flex',
					height: '100vh',
					mt: 2,
					ml: 2,
				}}>
					<CssBaseline />
					{loading && (
						<Box>
							<CircularProgress />
						</Box>
					)}				
					{!loading && selectablePokemon && pokemonMap && (
						<Box>
							<Paper elevation={1} sx={{ display: 'flex', height: 'fit-content' }}>
								<Box>
									<Typography variant="body1" sx={{ ml: 2, mt: 2 }}>
										Select which pokemon are draftable in this league
									</Typography>
									<Paper elevation={4} sx={{ m: 2 }}>
										<Box sx={{ height: 800, width: 700 }}>
											<DataGrid
												rows={rows}
												columns={columns}
												checkboxSelection
												selectionModel={selectedRows}
												onSelectionModelChange={(newSelection) => {
													setSelectedRows(newSelection as number[]);
												}}
											/>
										</Box>
									</Paper>
									<Typography variant="body1" sx={{ ml: 2, mt: 3 }}>
										Optionally set the tier for each selected pokemon (click tier number to edit). If you are drafting with cost enabled, the cost will be equal to the tier.
									</Typography>
									<Paper elevation={4} sx={{ m: 2 }}>
										<CostTable selectablePokemon={selectablePokemon} pokemonMap={pokemonMap} selectedRows={selectedRows} />
									</Paper>
								</Box>
							</Paper>
						</Box>
					)}
				</Box>
				<Box marginTop={1} display="flex" flexDirection="column">
					<Box display="flex" alignItems="center">
						<Button variant="contained" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={saveButtonClick}>
							Save
						</Button>
						<Button sx={{ m: 1 }} variant="contained" color="primary" component={CSVLinkRef} data={dataForExport()} headers={exportHeaders} filename="pokemon_draft_settings.csv">
							Export settings to CSV
						</Button>
						<Button
							variant="contained"
							color="primary"
							component="label"
							sx={{ m: 1 }}
						>
							Import settings CSV
							<input
								type="file"
								hidden
								id="import-settings"
								onChange={handleFileImport}
								ref={fileInputRef}
							/>
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(1, true, setSelectedRows)}>
							Select all gen 1
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(1, false, setSelectedRows)}>
							Deselect all gen 1
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(2, true, setSelectedRows)}>
							Select all gen 2
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(2, false, setSelectedRows)}>
							Deselect all gen 2
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(3, true, setSelectedRows)}>
							Select all gen 3
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(3, false, setSelectedRows)}>
							Deselect all gen 3
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(4, true, setSelectedRows)}>
							Select all gen 4
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(4, false, setSelectedRows)}>
							Deselect all gen 4
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(5, true, setSelectedRows)}>
							Select all gen 5
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(5, false, setSelectedRows)}>
							Deselect all gen 5
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(6, true, setSelectedRows)}>
							Select all gen 6
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(6, false, setSelectedRows)}>
							Deselect all gen 6
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(7, true, setSelectedRows)}>
							Select all gen 7
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(7, false, setSelectedRows)}>
							Deselect all gen 7
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(8, true, setSelectedRows)}>
							Select all gen 8
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(8, false, setSelectedRows)}>
							Deselect all gen 8
						</Button>
					</Box>
					<Box display="flex">
						<Button variant="outlined" color="primary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(9, true, setSelectedRows)}>
							Select all gen 9
						</Button>
						<Button variant="outlined" color="secondary" sx={{ m: 1, height: "fit-content" }} onClick={() => toggleGeneration(9, false, setSelectedRows)}>
							Deselect all gen 9
						</Button>
					</Box>
				</Box>				
			</Box>
		</Box>
	);
}
