import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import * as React from 'react';
import { Season } from '../types/Season';
import { useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import HistoryIcon from '@mui/icons-material/History';

export interface SeasonSelectProps {
    seasons: Season[];
    selectedLeague: { leagueId: string, pastSeasonId: string };
    setSelectedLeague: (a: { leagueId: string, pastSeasonId: string }) => void;
}

export default function SeasonSelect(props: SeasonSelectProps) {
    const { seasons, selectedLeague, setSelectedLeague } = props;
    const [selectedValue, setSelectedValue] = useState<string>("");
    const isMobile = useMediaQuery('(max-width: 600px)');
    const [dialogOpen, setDialogOpen] = useState(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (selectedLeague.pastSeasonId) {
            setSelectedValue(selectedLeague.pastSeasonId);
        }
        else {
            const initialActiveSeason = seasons.find(season => season.Active);
            setSelectedValue(initialActiveSeason?.ExternalId ?? "");
        }        
    }, [seasons]);

    const handleDialogClose = () => setDialogOpen(false);

    if (seasons.length <= 1) {
        return null;
    }

    const handleSeasonChange = (event: SelectChangeEvent<string>) => {
        setSelectedLeague({ ...selectedLeague, pastSeasonId: event.target.value });
        setSelectedValue(event.target.value);
    };

	return (
        <Box>
            <IconButton color="primary" onClick={() => setDialogOpen(true)}>
                <HistoryIcon />
            </IconButton>
            <Dialog
                fullScreen={isSmallScreen}
                open={dialogOpen}
                onClose={handleDialogClose}
                PaperProps={{
                    sx: {
                        maxWidth: '300px',
                        margin: theme.spacing(2),
                    },
                }}
            >
                <DialogTitle>Other Seasons</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                        <InputLabel id="season-select-label">Other Seasons</InputLabel>
                        <Select
                            labelId="season-select-label"
                            id="season-simple-select"
                            value={selectedValue}
                            label="Team"
                            onChange={handleSeasonChange}
                            native={isMobile} // Use native dropdown on mobile
                        >
                            {seasons.map(season => (
                                isMobile ? (
                                    <option key={season.ExternalId} value={season.ExternalId}>
                                        {season.Active ? "Active Season" : season.ExternalId}
                                    </option>
                                ) : (
                                    <MenuItem key={season.ExternalId} value={season.ExternalId}>
                                        {season.Active ? "Active Season" : season.ExternalId}
                                    </MenuItem>
                                )
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
            </Dialog>
        </Box>
	)
		
};