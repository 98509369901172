import { useHistory } from "react-router-dom";
import useToken from "../useToken";
import { AppBar, Box, Button, CssBaseline, Grid, IconButton, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import logo from '../assets/logo-transparent-no-padding.png';
import trainerImage from '../assets/trainer-arena-1.jpg';
import discordLogo from '../assets/discord-logo-white.svg';
import BallotIcon from '@mui/icons-material/Ballot';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export default function PublicHome() {
    const history = useHistory();
    const { token } = useToken();
    
    if (token) {
        history.push("/home");
    }
    
    return (
        <Box>
            <CssBaseline />
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Draft League
                    </Typography>
                    <Button color="inherit" onClick={() => history.push('/home')}>Login</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box marginTop={2} marginLeft={2}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={8}>
                    <Typography variant="h4">
                        Manage your Pokemon Draft League
                    </Typography>
                    <Typography variant="h6" marginTop={2} color={"neutral.main"}>
                        Grab your friends and draft teams of Pokemon. Then battle it out to see who is the best. This free tool will help you manage your league before, during, and after the draft.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/home')}
                        sx={{ fontSize: '16px', mt: 2 }}
                    >
                        Get Started
                    </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <img src={trainerImage} alt="Logo" width="300" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, minHeight: '400px', mt: 3 }}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={3.5}
                        minHeight={150}
                        key={'1'}
                        m={2}
                        sx={{ display: 'flex', alignItems: 'stretch' }}
                    >
                        <Paper sx={{ textAlign: 'center', p: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <BallotIcon color="primary" fontSize="large" />
                                <Typography ml={0.5}>
                                    Draft
                                </Typography>
                            </Box>
                            <Typography>
                                Customize which Pokemon can be drafted and make your own tier list. Once you
                                start drafting, you'll be able to see which pokemon are available at a glance.
                                We show your team's stats and weaknesses so you can make the best pick.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3.5}
                        minHeight={150}
                        key={'2'}
                        m={2}
                        sx={{ display: 'flex', alignItems: 'stretch' }}
                    >
                        <Paper sx={{ textAlign: 'center', p: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CatchingPokemonIcon color="primary" fontSize="large" />
                                <Typography ml={0.5}>
                                    Prepare
                                </Typography>
                            </Box>
                            
                            <Typography>Prepare for your matches after the draft. We show you your opponent's stats, weaknesses, and speed tiers in the app, no need for bespoke spreadsheets.</Typography>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3.5}
                        minHeight={150}
                        key={'3'}
                        m={2}
                        sx={{ display: 'flex', alignItems: 'stretch' }}
                    >
                        <Paper sx={{ textAlign: 'center', p: 1 }}>
                            <Box m={0.75}>
                                <img src={discordLogo} alt="Logo" width="125px"/>
                            </Box>
                            <Typography>Integrates with your discord channel. You'll receive draft updates and reminders without needing to download another one-off messaging app.</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}