import { Type } from "../types/PokemonType";
import { Pokemon } from "../types/Pokemon";

export default function abilityImmunities() {
    const getImmuneAbilities = (type: Type) => {
        switch (type) {
            case Type.Water:
                return [
                    "Dry Skin",
                    "Storm Drain",
                    "Water Absorb"
                ]
            case Type.Ground:
                return [
                    "Earth Eater",
                    "Levitate"
                ]
            case Type.Fire:
                return [
                    "Flash Fire",
                    "Well-Baked Body"
                ]
            case Type.Electric:
                return [
                    "Lightning Rod",
                    "Motor Drive",
                    "Volt Absorb"
                ]
            case Type.Grass:
                return [
                    "Sap Sipper"
                ]
            default:
                return []
        }
    }

    const isImmune = (mon: Pokemon, type: any) => {
        const immuneAbilities = getImmuneAbilities(type);

        // get all abilities
        const abilities = [mon.Ability1, mon.Ability2, mon.Ability3].filter((item) => item !== null);
        return abilities.some(ab => immuneAbilities.includes(ab));
    }

    return {
        getImmuneAbilities,
        isImmune
    }
}