import * as React from 'react';
import { useMemo } from 'react';
import { Pokemon } from '../types/Pokemon';
import getPokemonName from '../util/pokeFormatting';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { calcStatNonHP } from '../util/StatCalc';
import PokemonImage from './PokemonImage';

/* 
Table Structure
mon | Base | 0 EV | 252 EV + Neutral Nature | 252 EV + Positive Nature | 252 EV + Positive Nature + Choice
*/
function getSpeedRowForPokemon(mon: Pokemon, index: number) {
    if (!mon) {
        return;
    }

    let pokemonName = getPokemonName(mon);

    let cells = [];
    cells.push(
        <TableCell key={pokemonName + 'Name' + index}>
            {mon && (<PokemonImage pokemon={mon} />)}
            {pokemonName}
        </TableCell>
    );
    /* Base */
    cells.push(
        <TableCell key={pokemonName + 'baseSpeed' + index}>
            {mon.Speed}
        </TableCell>
    )
    /* 0 EV */
    cells.push(
        <TableCell key={pokemonName + '0EV' + index}>
            {calcStatNonHP(mon.Speed, 100, 31, 0, 1)}
        </TableCell>
    );
    /* 252 EV + Neutral nature */
    cells.push(
        <TableCell key={pokemonName + '252EVNeutNat' + index}>
            {calcStatNonHP(mon.Speed, 100, 31, 252, 1)}
        </TableCell>
    );
    /* 252 EV + Positive nature */
    cells.push(
        <TableCell key={pokemonName + '252EVPosNat' + index}>
            {calcStatNonHP(mon.Speed, 100, 31, 252, 1.1)}
        </TableCell>
    );
    /* 252 EV + Positive Nature + Choice */
    cells.push(
        <TableCell key={pokemonName + 'Choice' + index}>
            {Math.floor(1.5 * calcStatNonHP(mon.Speed, 100, 31, 252, 1.1))}
        </TableCell>
    );

    return (
        <TableRow key={pokemonName + 'speedrow' + index}>
            {cells.map((cell) => cell)}
        </TableRow>
    );
}

function getSpeedRowsForPokemon(pokemon: Pokemon[]) {
    if (!pokemon) {
        return [];
    }
    const sortedMon = [...pokemon].sort(function (a, b) { return b.Speed - a.Speed });

    const rows = sortedMon.map((mon, index) => getSpeedRowForPokemon(mon, index));

    return rows;
}

type Props = {
    pokemon: Pokemon[],
    teamId?: number,
}


export default function TeamSpeedTable({ pokemon, teamId }: Props) {
    const rows = useMemo(() => getSpeedRowsForPokemon(pokemon), [pokemon]);

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    Team Speeds (Lvl 100, 31 IV)
                </Typography>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'speedheader'}>
                                <TableCell key={'speedPokemon'}>Pokemon</TableCell>
                                <TableCell key={'speedBase'}>Base</TableCell>
                                <TableCell key={'speed0EV'}>0 EV</TableCell>
                                <TableCell key={'speed252EVNeut'}>252 EV + Neutral Nature</TableCell>
                                <TableCell key={'speed252EVPos'}>252 EV + Pos Nature</TableCell>
                                <TableCell key={'speedChoice'}>252 EV + Pos Nature + Choice Scarf</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}