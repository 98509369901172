import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import CircularProgress from '@mui/material/CircularProgress';

interface Member {
    UserId: number,
    DisplayName: string,
}

interface GetMembersResponse {
	members: Member[]
}

async function getLeagueMembersRequest(leagueId: string, token: Token, setMembers: (s: Member[]) => void, setLoading: (b: boolean) => void) {
    fetch('/League/Members/' + leagueId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message: GetMembersResponse = await response.json();

        if (!response.ok) {
            return;
        }

        setMembers(message.members);
        setLoading(false);
    });
}

interface RemoveMemberRequest {
    UserId: number,
    LeagueId: string,
}

async function sendRemoveMemberRequest(request: RemoveMemberRequest, token: Token) {
    fetch('/League/RemoveMember', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();

        if (!response.ok) {
            alert(message);
            return;
        }

        alert("Member removed - refresh page to see change reflected");

    });
}

interface Props {
    leagueId: string,
    seasonId?: string,
}

export default function MemberSettingsTab({ leagueId, seasonId }: Props) {
    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [members, setMembers] = useState<Member[]>([]);

    useEffect(() => {
        if (token && loading && leagueId && seasonId) {
            getLeagueMembersRequest(leagueId, token, setMembers, setLoading);            
        }
    }, [token, loading, leagueId, seasonId]);

    const submitRemoveMember = async (userId: number) => {
        sendRemoveMemberRequest({ UserId: userId, LeagueId: leagueId }, token);
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{ mt: 1, height: '650px' }}>
                {loading && (
                    <Box>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Box>
                        <Typography variant="h6">
                            Member Settings
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            Manage league members
                        </Typography>
                        <Box>
                            {members.map((member) => (
                                <Box key={member.UserId} sx={{ display: 'flex', mt: 3 }} >
									<Typography variant="body1" sx={{ mt: 1 }} >
										{member.DisplayName}
									</Typography>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={() => submitRemoveMember(member.UserId)}
                                        sx={{ ml: 2 }}
									>
										Remove
									</Button>
								</Box>
                            ))}
                            {members.length == 0 && (
                                <Typography variant="body1" sx={{ mt: 4 }} >
                                    Looks like it's just you in here. Invite some friends to join your league!
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
}

