import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { RequestStatus } from '../../types/RequestStatus';
import { Token } from '../../types/Token';
import { Team } from '../../types/Team';
import useToken from '../../useToken';
import RequestStatusIndicator from '../RequestStatusIndicator';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface GetTeamData {
    Team: Team,
}

async function getTeamRequest(leagueId: string, seasonId: string, token: Token, setData: (s: any) => void, setLoading: (b: boolean) => void) {
    fetch('/Team/Get/' + leagueId + "/" + seasonId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        setLoading(false);

        if (!response.ok) {
            return;
        }

        setData(message);
    });
}

interface SendUpdateRequest {
    LeagueId: string,
    SeasonId: string,
    NewTeamName: any,
}

async function sendUpdateRequest(request: SendUpdateRequest, token: Token, setUpdateRequestStatus: (s: RequestStatus) => void) {
    fetch('/Team/Update', {
        method: 'PUT',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            setUpdateRequestStatus(RequestStatus.Failed);
            return;
        }

        setUpdateRequestStatus(RequestStatus.Successful);
    });
}

interface SendLeaveRequest {
    LeagueId: string,
}

async function sendLeaveRequest(request: SendLeaveRequest, token: Token) {
    fetch('/User/LeaveLeague', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            alert("Failed to leave league");
            return;
        }

        window.location.reload();
    });
}

interface Props {
    leagueId: string,
    seasonId?: string,
}

export default function TeamSettingsTab({ leagueId, seasonId }: Props) {
    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<GetTeamData>();
    const [updateRequestStatus, setUpdateRequestStatus] = useState<RequestStatus>(RequestStatus.None);
    const [leaveLeagueDialogOpen, setLeaveLeagueDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (token && loading && leagueId && seasonId) {
            getTeamRequest(leagueId, seasonId, token, setData, setLoading);
        }
    }, [token, loading, leagueId, seasonId]);

    const submitTeamUpdate = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        await sendUpdateRequest({
            LeagueId: leagueId,
            SeasonId: seasonId ?? "",
            NewTeamName: data.get('teamName'),
        }, token, setUpdateRequestStatus);
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{ mt: 1, height: '650px' }}>
                {loading && (
                    <Box>
                        <CircularProgress />
                    </Box>
                )}
                {!data?.Team && !loading && (
                    <Box>
                        It looks like you don't have a team in this league
                    </Box>
                )}
                {data?.Team && !loading && (
                    <Box>
                        <Typography variant="h6">
                            Team Settings
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            Set team name
                        </Typography>
                        <Box component="form" onSubmit={submitTeamUpdate}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="teamName"
                                label="Team Name"
                                name="teamName"
                                defaultValue={data.Team.Name}
                            />
                            <Box sx={{ display: 'flex', mt: 3, mb: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Update Team
                                </Button>
                                <Box sx={{ mt: '6px', ml: '6px' }} >
                                    <RequestStatusIndicator status={updateRequestStatus} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
                {!loading && (
                    <>
                        <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => setLeaveLeagueDialogOpen(true)}>
                            Leave league
                        </Button>
                        <Dialog
                            open={leaveLeagueDialogOpen}
                            onClose={() => setLeaveLeagueDialogOpen(false)}
                        >
                            <DialogTitle>
                                Are you sure?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    This will revoke your access to the league and delete your team. This cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setLeaveLeagueDialogOpen(false)}>Cancel</Button>
                                <Button onClick={() => sendLeaveRequest({ LeagueId: leagueId }, token)} autoFocus>
                                    Leave league
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </Box>            
        </>
    );
}

