export function calcStatNonHP(base: number, level: number, iv: number, ev: number, natureMult: number) {
    let statCalc = (2 * base + iv + Math.floor(ev / 4)) * level;
    statCalc = Math.floor(statCalc / 100) + 5;
    statCalc = Math.floor(statCalc * natureMult);
    return statCalc;
}

export function calcStatHP(base: number, level: number, iv: number, ev: number) {
    let statCalc = (2 * base + iv + Math.floor(ev / 4)) * level;
    statCalc = Math.floor(statCalc / 100) + level + 10;
    return statCalc;
}