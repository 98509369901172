import * as React from 'react';
import Box from '@mui/material/Box';
import { PokemonTeam } from '../../types/PokemonTeam';
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import { useMemo, useState } from 'react';

function getMenuItemsForTeams(teams: PokemonTeam[]) {
	const options = [];

	for (let i = 0; i < teams.length; i++) {
		let teamName = (teams[i].TeamName) ? `${teams[i].TeamName} (${teams[i].OwnerDisplayName})` : teams[i].OwnerDisplayName;
		options.push(<MenuItem value={teams[i].TeamId}>{teamName}</MenuItem>);
	}

	return options;
}

interface SubmitMatchRequest {
	LeagueId: string,
	SeasonId: string,
	ScheduleId: string | null,
	ReplayUrl: string,
}

interface SubmitMatchManualRequest {
	LeagueId: string,
	SeasonId: string,
	ScheduleId: string | null,
	WinningTeamId: number,
	LosingTeamId: number,
}

async function submitMatch(request: SubmitMatchRequest, token: Token, callback?: () => void) {
	fetch('/Match/Replay', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		alert(message);

		if (callback) {
			callback();
		}		
	});
}

async function submitMatchManual(request: SubmitMatchManualRequest, token: Token, callback?: () => void) {
	fetch('/Match/Manual', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		alert(message);

		if (callback) {
			callback();
		}		
	});
}

interface MatchTabProps {
	teams: PokemonTeam[],
	leagueId: string,
	seasonId: string,
	leagueOwner: boolean,
	scheduleId?: string,
	callback?: () => void,
}

export default function MatchTab({ teams, leagueId, seasonId, scheduleId, leagueOwner, callback }: MatchTabProps) {
	const { token } = useToken();
	const [winningTeam, setWinningTeam] = useState('');
	const [losingTeam, setLosingTeam] = useState('');

	const onSubmitReplay = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const replayUrl = data.get('replayUrl')?.toString() ?? "";
		submitMatch({
			LeagueId: leagueId,
			SeasonId: seasonId,
			ScheduleId: scheduleId ?? null,
			ReplayUrl: replayUrl,
		}, token, callback);
	};

	const onSubmitManual = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		submitMatchManual({
			LeagueId: leagueId,
			SeasonId: seasonId,
			ScheduleId: scheduleId ?? null,
			WinningTeamId: Number(winningTeam),
			LosingTeamId: Number(losingTeam),
		}, token);
	};


	const teamSelectOptions = useMemo(() => getMenuItemsForTeams(teams), [teams]);
	console.log(teamSelectOptions);

	return (
		<>
			{(!teams || teams.length === 0) && (
				<Box>
					Matches can be submitted here after the draft is over.
				</Box>
			)}
			{teams.length > 0 && (
				<Box>
					<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
						<Box>
							<Typography variant="h6">
								Submit a match
							</Typography>
							<Box component="form" onSubmit={onSubmitReplay}>
								<Paper elevation={4} sx={{ p: 2, m: 2 }}>
									<Typography variant="body2" color="neutral">
										Paste the link from a pokemon showdown replay here to submit a match. All match participants must have their showdown usernames set in their user settings.
									</Typography>
									<Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
										<TextField
											variant="outlined"
											margin="none"
											required
											fullWidth
											id="replayUrl"
											label="Replay URL"
											name="replayUrl"
											autoFocus
										/>
										<Button
											type="submit"
											variant="outlined"
											color="primary"
										>
											Submit
										</Button>
									</Box>
								</Paper>
							</Box>
							{leagueOwner && (
								<Box component="form" onSubmit={onSubmitManual}>
									<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: 400 }}>
										<Typography variant="body2" color="neutral">
											League owners can manually submit matches here without a replay link.
										</Typography>
										<FormControl sx={{ mt: 1 }}>
											<InputLabel id="winning-team-select-label">Winning team</InputLabel>
											<Select
												labelId="winning-team-select-label"
												id="winning-team-select"
												label="Winning team"
												value={winningTeam}
												onChange={(event) => setWinningTeam(event.target.value)}
											>
												{teamSelectOptions}
											</Select>
										</FormControl>
										<FormControl sx={{ mt: 2 }}>
											<InputLabel id="losing-team-select-label">Losing team</InputLabel>
											<Select
												labelId="losing-team-select-label"
												id="losing-team-select"
												label="Losing team"
												value={losingTeam}
												onChange={(event) => setLosingTeam(event.target.value)}
											>
												{teamSelectOptions}
											</Select>
										</FormControl>
										<Button
											type="submit"
											variant="outlined"
											color="primary"
											sx={{ mt: 2 }}
										>
											Submit
										</Button>
									</Paper>
								</Box>
							)}
						</Box>
					</Paper>
				</Box>
			)}
		</>
	);
}