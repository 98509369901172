import * as React from 'react';
import { Component } from 'react';
import { Route } from 'react-router-dom';
import { CacheBuster } from './components/CacheBuster';
import Home from './components/Home';
import CreateLeague from './components/CreateLeague';
import Draft from "./components/Draft/Draft";
import './custom.css'
import Invite from './components/Invite';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import PokemonManager from './components/PokemonManager/PokemonManager';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ResetPassword from './components/PasswordReset/ResetPassword';
import useToken from './useToken';
import PublicHome from './components/PublicHome';

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
    }
}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#5dd6b7',
        },
        secondary: {
            main: '#d65d7b',
        },
        background: {
            paper: '#242d43',
            default: '#121723',
        },
        neutral: {
            main: '#949998',
        },
    },
};

const theme = createTheme(themeOptions);

export default class App extends Component {
  static displayName = App.name;

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }: any) => {
                        if (loading) return null;
                        if (!loading && !isLatestVersion) {
                            refreshCacheAndReload();
                        }

                        return (
                        
                                <div>
                                    <Route exact path='/' component={PublicHome} />
                                    <Route exact path='/home' component={Home} />
                                    <Route path="/home/:leagueIdStr" component={Home} />
                                    <Route path="/invite/:id" component={Invite} />
                                    <Route path="/CreateLeague" component={CreateLeague} />
                                    <Route path="/Draft/:draftIdStr" component={Draft} />
                                <Route path="/PokemonManager/:leagueIdStr" component={PokemonManager} />
                                <Route path="/ResetPassword/:resetToken" component={ResetPassword} />
                                </div>
                        
                        );
                    }}
                </CacheBuster>
            </ThemeProvider>
        );
  }
}
