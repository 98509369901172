import { Pokemon } from "../types/Pokemon";

export default function getPokemonName(mon: Pokemon) {
    if (mon.Variation) {
        return mon.Name + " (" + mon.Variation + ")";
    }
    else {
        return mon.Name;
    }
}

/*
 * All pokemon sprites.
 * if id < 906: https://img.pokemondb.net/sprites/sword-shield/icon/{lowercase name}.png
 * if id >= 906: https://img.pokemondb.net/sprites/scarlet-violet/icon/{lowercase name}.png
 */

const overrides = {
    34: { variation: "partner-cap", gen: "scarlet-violet" },
    171: { variation: "paldean-combat", gen: "scarlet-violet" },
    172: { variation: "paldean-blaze", gen: "scarlet-violet" },
    173: { variation: "paldean-aqua", gen: "scarlet-violet" },
    180: { variation: "lets-go", gen: "scarlet-violet" },
    250: { variation: "paldean", gen: "scarlet-violet" },
    593: { variation: "origin", gen: "scarlet-violet" },
    595: { variation: "origin", gen: "scarlet-violet" },
    668: { variation: "white-striped", gen: "scarlet-violet" },
    676: { variation: "galarian-standard", gen: "sword-shield" },
    677: { variation: "galarian-zen", gen: "sword-shield" },
    813: { variation: "male", gen: "sword-shield" },
    814: { variation: "female", gen: "sword-shield" },
    1040: { variation: "male", gen: "scarlet-violet" },
    1041: { variation: "female", gen: "scarlet-violet" },
    1069: { variation: "ice-rider", gen: "scarlet-violet" },
    1070: { variation: "shadow-rider", gen: "scarlet-violet" },
    1074: { variation: "male", gen: "scarlet-violet" },
    1075: { variation: "female", gen: "scarlet-violet" },
    1090: { variation: "male", gen: "scarlet-violet" },
    1091: { variation: "female", gen: "scarlet-violet" },
    1101: { variation: "family4", gen: "scarlet-violet" },
    1191: { variation: "male", gen: "scarlet-violet" },
    1192: { variation: "male", gen: "scarlet-violet" },
    1193: { variation: "sensu", gen: "scarlet-violet" },
}

export function getIconURLForPokemon(pokemon: Pokemon) {
    if (!pokemon) {
        return "";
    }

    let lowerCaseNameWithoutSpaces = normalizeString(pokemon.Name);
    if (pokemon.Variation) {
        if (overrides[pokemon.UniqueId as keyof typeof overrides]) {
            lowerCaseNameWithoutSpaces += "-" + overrides[pokemon.UniqueId as keyof typeof overrides].variation;
        }
        else if (pokemon.Variation === "Alola") {
            lowerCaseNameWithoutSpaces += '-' + "alolan";
        }
        else if (pokemon.Variation === "Galar") {
            lowerCaseNameWithoutSpaces += '-' + "galarian";
        }
        else if (pokemon.Variation === "Hisui") {
            lowerCaseNameWithoutSpaces += '-' + "hisuian";
        }
        else {
            lowerCaseNameWithoutSpaces += "-" + normalizeString(pokemon.Variation);
        }		
    }

    let gen = "";
    if (pokemon.Id < 898 && pokemon.Variation !== "Hisui") {
        gen = "sword-shield";
    }
    else {
        gen = "scarlet-violet";
    }

    if (overrides[pokemon.UniqueId as keyof typeof overrides]) {
        gen = overrides[pokemon.UniqueId as keyof typeof overrides].gen;
    }

    return `https://img.pokemondb.net/sprites/${gen}/icon/${lowerCaseNameWithoutSpaces}.png`;
}

function normalizeString(str: string) {
    return str.toLowerCase().replace(" ", "-").replace(".", "").replace("%", "").replace(":", "").replace("'", "");
}
