import * as React from 'react';
import { PokemonTeam } from '../../../../types/PokemonTeam';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { HistoricalMatch } from '../../../../types/HistoricalMatch';
import { useMemo } from 'react';

interface StandingsProps {
	teams: PokemonTeam[],
	matchHistory: HistoricalMatch[],
}

function getRowsForStandings(matchHistory: HistoricalMatch[], teams: PokemonTeam[]) {
    let rows = [];

    // Get the number of wins and losses for each team
    let teamWins = new Map<number, number>();
    let teamLosses = new Map<number, number>();

    // Prefill the maps with the team IDs and 0 wins/losses
    for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        teamWins.set(team.TeamId, 0);
        teamLosses.set(team.TeamId, 0);
    }

    // Increment the wins and losses for each team
    for (let i = 0; i < matchHistory.length; i++) {
        const match = matchHistory[i];

        if (match.PlayerOne === match.Winner) {
            teamWins.set(match.PlayerOneTeamId, (teamWins.get(match.PlayerOneTeamId) ?? 0) + 1);
            teamLosses.set(match.PlayerTwoTeamId, (teamLosses.get(match.PlayerTwoTeamId) ?? 0) + 1);
        }
        else if (match.PlayerTwo === match.Winner) {
            teamWins.set(match.PlayerTwoTeamId, (teamWins.get(match.PlayerTwoTeamId) ?? 0) + 1);
            teamLosses.set(match.PlayerOneTeamId, (teamLosses.get(match.PlayerOneTeamId) ?? 0) + 1);
        }
        // If the match has no players or url, then the winning team id is set in the winner field
        else if (match.PlayerOneTeamId === Number(match.Winner)) {
            teamWins.set(match.PlayerOneTeamId, (teamWins.get(match.PlayerOneTeamId) ?? 0) + 1);
            teamLosses.set(match.PlayerTwoTeamId, (teamLosses.get(match.PlayerTwoTeamId) ?? 0) + 1);
        }
        else if (match.PlayerTwoTeamId === Number(match.Winner)) {
			teamWins.set(match.PlayerTwoTeamId, (teamWins.get(match.PlayerTwoTeamId) ?? 0) + 1);
			teamLosses.set(match.PlayerOneTeamId, (teamLosses.get(match.PlayerOneTeamId) ?? 0) + 1);
		}
    }

    // Sort the teams by wins
    const sortedTeams = [...teams].sort((a, b) => {
        const aWins = teamWins.get(a.TeamId) ?? 0;
        const bWins = teamWins.get(b.TeamId) ?? 0;
        if (aWins > bWins) {
            return -1;
        }
        else if (aWins < bWins) {
            return 1;
        }
        else {
            return 0;
        }
    });

    // Create the rows
    for (let i = 0; i < sortedTeams.length; i++) {
        const team = sortedTeams[i];
        const nameToDisplay = team.TeamName && team.TeamName.length > 0 ? team.TeamName : team.OwnerDisplayName;
        const wins = teamWins.get(team.TeamId) ?? 0;
        const losses = teamLosses.get(team.TeamId) ?? 0;
        rows.push(
            <TableRow key={team.TeamId}>
                <TableCell key={team.TeamId + 'team'}>{nameToDisplay}</TableCell>
                <TableCell key={team.TeamId + 'wins'}>{wins}</TableCell>
                <TableCell key={team.TeamId + 'losses'}>{losses}</TableCell>
            </TableRow>
        );
    }

    return rows;
}

export default function Standings({ matchHistory, teams }: StandingsProps) {
    const rows = useMemo(() => getRowsForStandings(matchHistory, teams), [matchHistory, teams]);

	return (
		<>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden', maxWidth: '800px' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    Standings
                </Typography>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'standingsHeader'}>
                                <TableCell key={'team'}>Team</TableCell>
                                <TableCell key={'wins'}>Wins</TableCell>
                                <TableCell key={'losses'}>Losses</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
		</>
	);
}