import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { PokemonTeam } from '../types/PokemonTeam';
import getPokemonName from '../util/pokeFormatting';
import PokemonImage from './PokemonImage';

interface Props {
	team: PokemonTeam;
}

const MOVES_THAT_SWITCH_OUT = [
	"baton-pass",
	"chilly-reception",
	"u-turn",
	"volt-switch",
	"flip-turn",
	"parting-shot",
	"teleport",
	"shed-tail",
];

const MOVES_THAT_TAUNT = [
	"taunt"
];

const MOVES_THAT_MANIP_ITEM = [
	"bestow",
	"bug-bite",
	"corrosive-gas",
	"covet",
	"embargo",
	"fling",
	"g-max-replenish",
	"incinerate",
	"knock-off",
	"magic-room",
	"natural-gift",
	"pluck",
	"recycle",
	"stuff-cheeks",
	"switcheroo",
	"teatime",
	"thief",
	"trick",
];

const MOVES_THAT_SET_SCREEN = [
	"aurora-veil",
	"baddy-bad",
	"g-max-resonance",
	"glitzy-glow",
	"light-screen",
	"relect",
];

const MOVES_THAT_REMOVE_SCREEN = [
	"brick-break",
	"defog",
	"g-max-wind-rage",
	"psychic-fangs",
	"raging-bull",
	"shadow-shed",
];

const MOVES_THAT_HAZARD_SET = [
	"ceaseless-edge",
	"g-max-steelsurge",
	"g-max-stonesurge",
	"spikes",
	"stealth-rock",
	"sticky-web",
	"stone-axe",
	"toxic-spikes",
];

const MOVES_THAT_HAZARD_REMOVE = [
	"defog",
	"g-max-wind-rage",
	"mortal-spin",
	"rapid-spin",
	"tidy-up",
];

const MOVES_THAT_PRIORITY = [
	"accelerock",
	"aqua-jet",
	"baby-doll-eyes",
	"bullet-punch",
	"grassy-glide",
	"ice-shard",
	"jet-punch",
	"mach-punch",
	"quick-attack",
	"shadow-sneak",
	"sucker-punch",
	"vacuum-wave",
	"water-shuriken",
	"ally-switch",
	"extreme-speed",
	"feint",
	"first-impression",
	"follow-me",
	"rage-powder",
	"crafty-shield",
	"fake-out",
	"quick-guard",
	"wide-guard",
	"baneful-bunker",
	"detect",
	"endure",
	"kings-shield",
	"max-guard",
	"obstruct",
	"protect",
	"spiky-shield",
	"silk-trap",
	"helping-hand",
];

const MOVE_LISTS = {
	switchOut: MOVES_THAT_SWITCH_OUT,
	taunt: MOVES_THAT_TAUNT,
	manipItem: MOVES_THAT_MANIP_ITEM,
	setScreen: MOVES_THAT_SET_SCREEN,
	removeScreen: MOVES_THAT_REMOVE_SCREEN,
	hazardSet: MOVES_THAT_HAZARD_SET,
	hazardRemove: MOVES_THAT_HAZARD_REMOVE,
	priority: MOVES_THAT_PRIORITY,
};

const MOVE_LIST_NAMES = {
	switchOut: "Switch Out",
	taunt: "Taunt",
	manipItem: "Manipulate Item",
	setScreen: "Set Screen",
	removeScreen: "Remove Screen",
	hazardSet: "Hazard Set",
	hazardRemove: "Hazard Remove",
	priority: "Priority",
};

type PokemonFromApi = {
	name: string;
	moves: { move: { name: string } }[];
}

function findMoves(pokemon: PokemonFromApi) {
	const result: { move: string; list: keyof typeof MOVE_LISTS }[] = [];

	pokemon.moves.forEach((move) => {
		for (const listName in MOVE_LISTS) {
			if (MOVE_LISTS[listName as keyof typeof MOVE_LISTS].includes(move.move.name)) {
				result.push({ move: move.move.name, list: listName as keyof typeof MOVE_LISTS });
			}
		}
	});

	return result;
}

function getRowsForList(team: PokemonTeam, listName: string, moves: { pokemonId: any; move: string }[]) {
	let rows: JSX.Element[] = [];
	let pokemonMovesMap: { [key: string]: string[] } = {};

	// Create a mapping of Pokemon to their moves
	moves.forEach((move) => {
		const mon = team.Pokemon.find((mon) => mon.UniqueId === move.pokemonId);
		if (mon) {
			const pokemonName = getPokemonName(mon);
			if (!pokemonMovesMap[pokemonName]) {
				pokemonMovesMap[pokemonName] = [move.move];
			} else {
				pokemonMovesMap[pokemonName].push(move.move);
			}
		}
	});

	// Iterate over the mapping and create a row for each Pokemon
	Object.entries(pokemonMovesMap).forEach(([pokemonName, moves]) => {
		const mon = team.Pokemon.find((mon) => getPokemonName(mon) === pokemonName);
		rows.push(
			<TableRow key={pokemonName + listName}>
				<TableCell>
					{mon && (<PokemonImage pokemon={mon} />)}
					{pokemonName}
				</TableCell>
				<TableCell>{moves.join(', ')}</TableCell>
			</TableRow>
		);
	});

	return rows;
}

export default function PokemonMoveCategories({ team }: Props) {
	const [aggregatedData, setAggregatedData] = useState<Record<string, {
        pokemonId: number; move: string; list: keyof typeof MOVE_LISTS 
	}[]>>({});
	const [loading, setLoading] = useState(true);

	useMemo(() => {
		setAggregatedData({});
		setLoading(true);

		Promise.all(team.Pokemon.map(async (pokemon, index) => {
			const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${pokemon.Name.toLowerCase().replace(" ", "-")}`);
			if (response.ok) {
				const data: PokemonFromApi = await response.json();
				const moves = findMoves(data);
				return moves.map(move => ({ ...move, pokemonId: pokemon.UniqueId }));
			}
			else {
				console.log("Failed to fetch pokemon data for " + pokemon.Name);
			}
			return [];
		})).then(allMoves => {
			const newAggregatedData = allMoves.reduce((acc: any, moves, index) => {
				moves.forEach(move => {
					if (!acc[move.list]) {
						acc[move.list] = [];
					}
					acc[move.list].push(move);
				});
				return acc;
			}, {});
			setAggregatedData(newAggregatedData);
			setLoading(false);
		});
	}, [team]);
	
	return (
		<div>
			<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
				<Box>
					<Typography variant="h6">
						Important Moves*
					</Typography>
					<Typography variant="body2" color="neutral">
						*Currently shows moves for whatever the base Pokemon is. For example, Alolan Raichu will show moves for normal Raichu.
					</Typography>
					{loading && <CircularProgress />}
					{!loading && (
						<>
							{/* Iterate over MOVE_LISTS array to maintain order */ }
							{Object.keys(MOVE_LISTS).map(listName => {
								if (aggregatedData[listName]) {
									return (
										<Paper elevation={5} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
											<Typography component="h2" variant="h6" gutterBottom>
												{MOVE_LIST_NAMES[listName as keyof typeof MOVE_LIST_NAMES]}
											</Typography>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow key={'header' + listName}>
															<TableCell sx={{ width: "50%" }} key={'pokemon' + listName}>Pokemon</TableCell>
															<TableCell key={'move' + listName}>Move</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{getRowsForList(team, listName, aggregatedData[listName])}
													</TableBody>
												</Table>
											</TableContainer>
										</Paper>
									);
								}
							})}
						</>
					)}
				</Box>
			</Paper>
		</div>
	);
}