import React, { Component } from 'react';
import { Avatar, Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';

interface ForgotPasswordRequest {
    Email: any;
}

async function forgotPassword(request: ForgotPasswordRequest) {
    fetch('/Login/ForgotPassword/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    });
}

export default function ForgotPassword({ setLoginMode }: any) {

    const submitForgotPassword = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        forgotPassword({
            Email: data.get('email'),
        });
        alert("If there is an account associated with this email, a password reset email has been sent. Check your spam folder.");
    }

    return (
        <>
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            <Box component="form" onSubmit={submitForgotPassword}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Enter your email address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Send Password Reset Email
                </Button>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button
                            type="button"
                            fullWidth
                            onClick={() => setLoginMode("login")}
                            variant="text"
                        >
                            Back to login
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
