import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Typography,
    Tooltip,
    Box,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as React from 'react';
import { PendingPick } from '../../types/PendingPick';
import { Pokemon } from '../../types/Pokemon';
import getPokemonName from '../../util/pokeFormatting';

interface Props {
    submitPick: (pickNum: number, primary: Pokemon | null, backup: Pokemon | null) => Promise<void>;
    pendingPicks: PendingPick[];
    selectedPokemon: Pokemon | undefined;
    isOpen: boolean;
    onDialogClose: () => void;
    pickNumbers: number[];
}

export default function SubmitPickDialog({ submitPick, pendingPicks, selectedPokemon, isOpen, onDialogClose, pickNumbers }: Props) {
    if (!selectedPokemon) {
		return null;
    }

    const handleReplacePick = (order: number, isPrimary: boolean) => {
        const existingPick = pendingPicks.find(pick => pick.Pick === order);
        if (existingPick) {
            const updatedPick = { ...existingPick, [isPrimary ? 'PrimaryPick' : 'BackupPick']: selectedPokemon };
            submitPick(order, updatedPick.PrimaryPick, updatedPick.BackupPick);
        }
    };

    const nextAvailablePick = () => {
        return pickNumbers.find(num => !pendingPicks.some(pick => pick.Pick === num)) || 1;
    };

    const getNameForPick = (pick: Pokemon | null) => {
        if (!pick || pick.UniqueId === 0) {
			return "None";
        }

        return getPokemonName(pick);
    };

    const handleAddNewPick = (isPrimary: boolean) => {
        
        const newOrder = nextAvailablePick();
        submitPick(newOrder, isPrimary ? selectedPokemon : null, isPrimary ? null : selectedPokemon);
    };    

    return (
        <Dialog open={isOpen} onClose={onDialogClose}>
            <DialogTitle>Pick {getPokemonName(selectedPokemon)}?</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography component="h6" variant="h6" gutterBottom>
                        Queued picks
                    </Typography>
                    <Tooltip title="For each pick you can queue one primary and one backup. If your primary pick is taken by someone else then your backup pick will be used. If both are taken then no pokemon will be drafted for that pick until you pick an available pokemon.">
                        <InfoOutlinedIcon sx={{ ml: 0.5, mb: 0.5 }} />
                    </Tooltip>       
                </Box>                
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Pick</TableCell>
                                <TableCell>Primary</TableCell>
                                <TableCell>Backup</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pendingPicks.map((pick) => (
                                <TableRow key={pick.Pick}>
                                    <TableCell>{pick.Pick}</TableCell>
                                    <TableCell>
                                        <Stack alignItems="center">
                                            {getNameForPick(pick.PrimaryPick)}
                                            <Button onClick={() => handleReplacePick(pick.Pick, true)}>
                                                {pick.PrimaryPick && pick.PrimaryPick.UniqueId !== 0 ? `Replace` : 'Queue'}
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack alignItems="center">
                                            {getNameForPick(pick.BackupPick)}
                                            <Button onClick={() => handleReplacePick(pick.Pick, false)}>
                                                {pick.BackupPick && pick.BackupPick.UniqueId !== 0 ? `Replace` : 'Queue'}
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>{nextAvailablePick()}</TableCell>
                                <TableCell>
                                    <Stack>
                                        <Button onClick={() => handleAddNewPick(true)}>Queue</Button>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack>
                                        <Button onClick={() => handleAddNewPick(false)}>Queue</Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

