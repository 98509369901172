import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function useToken() {
    const { history } = useHistory();

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        return tokenString;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    };

    const clearToken = () => {
        localStorage.removeItem('token');
    }

    return {
        setToken: saveToken,
        clearToken,
        token
    }
}