import * as React from 'react';
import { useState, useEffect } from 'react';
import './css/Home.css';
import useToken from '../useToken';
import Login from '../components/Login';
import TeamTab from './HomeTabs/TeamTab';
import SettingsDialog from './Settings/SettingsDialog';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import { Token } from '../types/Token';
import { League } from '../types/League';
import { Team } from '../types/Team';
import { PokemonTeam } from '../types/PokemonTeam';
import LeaguesDrawer from './LeaguesDrawer';
import DraftTab from './HomeTabs/DraftTab/DraftTab';
import { Draft } from '../types/Draft';
import MatchTab from './HomeTabs/MatchTab';
import LeagueTab from './HomeTabs/LeagueTab/LeagueTab';
import PokemonTab from './HomeTabs/PokemonTab';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Season } from '../types/Season';
import SeasonSelect from './SeasonSelect';
import BugIcon from './BugIcon';

/*
 * DISCLAIMER:
 * This page is very much hacked together
 * Most of the contents of this file are temporary and will be replaced.
 */

const drawerWidth = 240;

interface Home {
    Leagues: League[],
}

async function fetchUserHome(token: Token, setHomeObj: (h: Home) => void, setLoading: (b: boolean) => void) {
    fetch('/User/Home', {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        if (!response.ok) {
            alert("AHHHHHHHHHH");
            return;
        }
        setHomeObj(message);
        setLoading(false);
    });
}

export interface SelectedLeague {
    CanStartDraft: boolean,
    IsLeagueOwner: boolean,
    CanAddTeamForSelf: boolean,
    HasDraftablePokemonSettings: boolean,
    DraftablePokemonJson: string,
    HasDraftWebhook: boolean,
    HasMatchWebhook: boolean,
    InviteCode: string,
    TeamId: number,
    ActiveTeams: Team[],
    PokemonTeams: PokemonTeam[],
    Draft: Draft,
    SeasonId: string,
    League: League,
    Seasons: Season[],
}

async function fetchLeagueHome(token: Token, leagueId: string, setSelectedLeagueHomeObj: (sl: SelectedLeague) => void) {
    fetch('/User/League/' + leagueId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        if (!response.ok) {
            alert("AHHHHHHHHHH");
            return;
        }
        setSelectedLeagueHomeObj(message);
    });
}

async function fetchLeagueHomeForSeason(token: Token, leagueId: string, seasonId: string, setSelectedLeagueHomeObj: (sl: SelectedLeague) => void) {
    fetch(`/User/League/${leagueId}/${seasonId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        if (!response.ok) {
            alert("AHHHHHHHHHH");
            return;
        }
        setSelectedLeagueHomeObj(message);
    });
}

type Props = {
    history: any,
    window?: () => Window;
}

interface LeagueAndSeasonId {
    leagueId: string,
    pastSeasonId: string,
}

interface HomeParams {
    leagueIdStr: string,
}

export default function Home({ history }: Props) {
    const { token, setToken } = useToken();
    let { leagueIdStr } = useParams<HomeParams>();

    const [loading, setLoading] = useState<boolean>(true);
    const [homeJsonObj, setHomeObj] = useState<Home>();
    const [selectedLeague, setSelectedLeague] = useState<LeagueAndSeasonId>();
    const [selectedLeagueHomeObj, setSelectedLeagueHomeObj] = useState<SelectedLeague>();
    const [tabNav, setTabNav] = useState<string>("1");
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [settingsDefaultTab, setSettingsDefaultTab] = useState<string>("1");

    useEffect(() => {
        // call initial load apis here
        if (token && loading) {
            fetchUserHome(token, setHomeObj, setLoading);
        }
    }, [token, loading]);

    // Load league-specfic info when selected league changes
    useEffect(() => {
        if (token && selectedLeague) {
            if (selectedLeague.pastSeasonId) {
                fetchLeagueHomeForSeason(token, selectedLeague.leagueId, selectedLeague.pastSeasonId, setSelectedLeagueHomeObj);
            }
            else {
                fetchLeagueHome(token, selectedLeague.leagueId, setSelectedLeagueHomeObj);
            }
            
        }
    }, [selectedLeague, token]);

    useEffect(() => {
        if (selectedLeagueHomeObj?.Draft?.Completed) {
            setTabNav("2");
        }
    }, [selectedLeagueHomeObj]);

    if (!token) {
        return <Login setToken={setToken} />
    }

    const updateSelectedLeague = (leagueId: string) => {
        history.replace('/home/' + leagueId);
        setSelectedLeague({ leagueId: leagueId, pastSeasonId: "" });
    };

    // --- Build various sections here with loaded data ---
    const handleSettingsClickOpen = (defaultTab?: string) => {
        setSettingsOpen(true);
        if (defaultTab) {
            setSettingsDefaultTab(defaultTab);
        }
        else {
			setSettingsDefaultTab("1");
        }
    }

    const handleSettingsClose = () => {
        setSettingsOpen(false);
        //reload?
    }

    // Creation <option> list of leagues
    let leagueList = [];
    if (homeJsonObj?.Leagues) {
        leagueList = homeJsonObj.Leagues.map((item, index) => {
            return <option key={index} value={item.ExternalId}>{item.Name}</option>
        });
    }

    // Default selected league
    if (!selectedLeague && leagueList.length > 0 && homeJsonObj?.Leagues && homeJsonObj.Leagues.length > 0) {
        // If we have a leagueId in the url, use that. Otherwise, use the first league in the list
        if (leagueIdStr) {
            updateSelectedLeague(leagueIdStr);
        }
        else {
            updateSelectedLeague(homeJsonObj?.Leagues[0].ExternalId);
        }        
    }

    // Build the invite url TODO: Update this to respect whether we are deployed or local 
    const inviteUrl = (selectedLeagueHomeObj?.InviteCode) ? "https://draftleague.net/invite/" + selectedLeagueHomeObj.InviteCode : "";

    const handleTabNavChange = (event: any, newValue: any) => {
        event.preventDefault();
        setTabNav(newValue);
    }
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <LeaguesDrawer
                    history={history}
                    drawerWidth={drawerWidth}
                    selectedLeague={selectedLeague?.leagueId}
                    setSelectedLeague={updateSelectedLeague}
                    leagues={homeJsonObj?.Leagues}
                />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                    }}
                >
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                        {/* Put page content in here */}
                        {loading && (
                            <Box sx={{ position: "fixed", top: "50%", left: "50%" }}>
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && !selectedLeague && (
                            <Box>
                                It looks like you aren't in any leagues. Ask your friends for an invite url to join their leagues. To create a new league, use the plus button on the left of the screen.
                            </Box>
                        )}
                        {!loading && selectedLeagueHomeObj && (
                            <Box>
                                {selectedLeague && selectedLeagueHomeObj && (
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                            {selectedLeagueHomeObj.League.Name === "Wooper Club League Season 16 Johto" ? (
                                                <>
                                                    <style>
                                                        {`
                                                          .sliding-header {
                                                            animation: slideRight 240s linear infinite;
                                                            will-change: transform; 
                                                            transform: translateZ(0); 
                                                          }

                                                          @keyframes slideRight {
                                                            0% {
                                                              transform: translateX(0);
                                                            }
                                                            100% {
                                                              transform: translateX(100px); 
                                                            }
                                                          }
                                                        `}
                                                    </style>
                                                    <h1 className="sliding-header">{selectedLeagueHomeObj.League.Name}</h1>  
                                                </>
                                            ) : (
                                                 <h1>{selectedLeagueHomeObj.League.Name}</h1>  
                                            )}                                                                                 
                                            <Box marginLeft={1}>
                                                <SeasonSelect
                                                    seasons={selectedLeagueHomeObj.Seasons}
                                                    selectedLeague={selectedLeague}
                                                    setSelectedLeague={setSelectedLeague}
                                                />
                                            </Box>
                                            
                                        </Box>
                                        <Button variant="outlined" color="primary" onClick={() => handleSettingsClickOpen()} sx={{ minWidth: "115px" }}>
                                            Settings
                                            <SettingsIcon fontSize="small" sx={{ ml: 1 }} />
                                        </Button>
                                        <SettingsDialog
                                            open={settingsOpen}
                                            onClose={handleSettingsClose}
                                            leagueId={selectedLeagueHomeObj.League.ExternalId}
                                            seasonId={selectedLeagueHomeObj?.SeasonId}
                                            leagueOwner={selectedLeagueHomeObj?.IsLeagueOwner}
                                            defaultTab={settingsDefaultTab}
                                        />
                                    </Box>
                                )}
                                <Paper elevation={1} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <TabContext value={tabNav}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabNavChange} aria-label="home tabs">
                                                <Tab label="Draft" value="1" />
                                                <Tab label="Team" value="2" />
                                                <Tab label="League" value="3" />
                                                <Tab label="Match" value="4" />
                                                <Tab label="Pokemon" value="5" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            {/*Draft*/}
                                            <Box>
                                                {selectedLeagueHomeObj && selectedLeague && (
                                                    <DraftTab
                                                        inviteUrl={inviteUrl}
                                                        activeTeams={selectedLeagueHomeObj.ActiveTeams}
                                                        handleSettingsClickOpen={handleSettingsClickOpen}
                                                        selectedLeague={selectedLeague.leagueId}
                                                        selectedLeagueHomeObj={selectedLeagueHomeObj}
                                                    />
                                                )}                                                
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            {/*Team*/}
                                            {selectedLeagueHomeObj?.PokemonTeams && (
                                                <Box>
                                                    <TeamTab teams={selectedLeagueHomeObj.PokemonTeams} seasonId={selectedLeagueHomeObj?.SeasonId} isLeagueOwner={selectedLeagueHomeObj.IsLeagueOwner} showTier={true} showFilter={true} defaultTeamId={selectedLeagueHomeObj.TeamId === -1 ? undefined : selectedLeagueHomeObj.TeamId} selectablePokemonJson={selectedLeagueHomeObj.DraftablePokemonJson === "" ? undefined : selectedLeagueHomeObj.DraftablePokemonJson} />
                                                </Box>
                                            )}
                                        </TabPanel>
                                        <TabPanel value="3">
                                            {/*League*/}
                                            {selectedLeagueHomeObj?.PokemonTeams && (
                                                <Box>
                                                    <LeagueTab teams={selectedLeagueHomeObj.PokemonTeams} leagueId={selectedLeagueHomeObj.League.ExternalId} seasonId={selectedLeagueHomeObj?.SeasonId} leagueOwner={selectedLeagueHomeObj?.IsLeagueOwner} userTeamId={selectedLeagueHomeObj.TeamId} />
                                                </Box>
                                            )}
                                        </TabPanel>
                                        <TabPanel value="4">
                                            {/*Match*/}
                                            {selectedLeagueHomeObj?.PokemonTeams && (
                                                <Box>
                                                    <MatchTab teams={selectedLeagueHomeObj.PokemonTeams} leagueId={selectedLeagueHomeObj.League.ExternalId} seasonId={selectedLeagueHomeObj?.SeasonId} leagueOwner={selectedLeagueHomeObj?.IsLeagueOwner} />
                                                </Box>
                                            )}
                                        </TabPanel>
                                        <TabPanel value="5">
                                            {/*Pokemon*/}
                                            {selectedLeagueHomeObj?.PokemonTeams && (
                                                <Box>
                                                    <PokemonTab teams={selectedLeagueHomeObj.PokemonTeams} leagueId={selectedLeagueHomeObj.League.ExternalId} seasonId={selectedLeagueHomeObj?.SeasonId} />
                                                </Box>
                                            )}
                                        </TabPanel>
                                    </TabContext>
                                </Paper>
                            </Box>
                        )}                        
                    </Container>
                </Box>
            </Box>
        </>
    );
}