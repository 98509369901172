import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { HistoricalMatch } from '../../../../types/HistoricalMatch';
import { PokemonTeam } from '../../../../types/PokemonTeam';
import { Token } from '../../../../types/Token';
import useToken from '../../../../useToken';
import MatchupCard, { DEFAULT_TIME } from './MatchupCard';
import MatchupEditor from './MatchupEditor';
import ScheduleCreator from './ScheduleCreator';

async function getScheduleRequest(seasonId: string, token: Token, setResponse: any) {
	fetch('/League/Schedule/' + seasonId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			return;
		}

		setResponse(message);
	});
}

async function updateScheduleRequest(request: UpdateScheduleRequest, token: Token, setIsLoading: (b: boolean) => void) {
	fetch('/Match/Update', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.text();
		if (!response.ok) {
			alert(message);
			return;
		}

		// Reload the schedule
		setIsLoading(true);
	});
}

function getMenuItemsForSchedule(schedule: Schedule[]) {
	const options = [];
	const nativeOptions = [];

	// Get unique number of weeks from schedule
	const weeks = new Set(schedule.map((match) => match.Week)).size;

	for (let i = 1; i <= weeks; i++) {
		options.push(<MenuItem value={i}>Week {i}</MenuItem>);
		nativeOptions.push(<option value={i}>Week {i}</option>);
	}

	return {
		options: options,
		nativeOptions: nativeOptions
	};
}

interface UpdateScheduleRequest {
	LeagueId: string,
	NewMatches: Schedule[],
	UpdatedMatches: Schedule[],
	DeletedMatches: string[],
}

type ScheduleResponse = {
	schedule: Schedule[];
}

export type Schedule = {
	Id: string,
	TeamOneId: number,
	TeamTwoId: number,
	ReplaySubmissionId?: number,
	Week: number,
	ScheduledTime?: string
}

interface MatchupsProps {
	leagueId: string,
	seasonId: string,
	leagueOwner: boolean,
	teams: PokemonTeam[],
	historicalMatches: HistoricalMatch[],
	userTeamId: number,
	reloadMatchHistory: () => void
}

export default function Matchups({ leagueId, seasonId, leagueOwner, teams, historicalMatches, userTeamId, reloadMatchHistory }: MatchupsProps) {
	const { token } = useToken();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [response, setResponse] = useState<ScheduleResponse>();
	const [selectedWeek, setSelectedWeek] = useState<string>("1");
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const [pendingSchedule, setPendingSchedule] = useState<Schedule[]>([]);

	const isMobile = useMediaQuery('(max-width: 600px)');

	useEffect(() => {
		if (isLoading) {
			setIsLoading(false);
			getScheduleRequest(seasonId, token, setResponse);			
		}		
	}, [seasonId, token, isLoading]);

	useEffect(() => {
		if (response?.schedule) {
			const newPendingSchedule: Schedule[] = [];

			for (let i = 0; i < response.schedule.length; i++) {
				newPendingSchedule.push({
					Id: response.schedule[i].Id,
					TeamOneId: response.schedule[i].TeamOneId,
					TeamTwoId: response.schedule[i].TeamTwoId,
					Week: response.schedule[i].Week,
				});
			}

			setPendingSchedule(newPendingSchedule);
		}		
	}, [response]);

	const reload = () => {
		setIsLoading(true);
		reloadMatchHistory();
	};

	if (!response) {
		return null;
	}

	const saveScheduleUpdates = () => {
		// Go through the pending schedule and determine which matches are new or deleted.
		// A match is deleted if the scheduled match id is not in the pending schedule.
		// A match is new if the scheduled match id in the pending schedule is 0.
		// A match is updated if the scheduled match id is not 0 but the team ids are different.

		const newMatches: Schedule[] = [];
		const deletedMatches: string[] = [];
		const updatedMatches: Schedule[] = [];

		for (let i = 0; i < response.schedule.length; i++) {
			const match = response.schedule[i];
			const pendingMatch = pendingSchedule.find((pendingMatch) => pendingMatch.Id === match.Id);

			if (!pendingMatch) {
				deletedMatches.push(match.Id);
			} else if (pendingMatch.TeamOneId !== match.TeamOneId || pendingMatch.TeamTwoId !== match.TeamTwoId) {
				updatedMatches.push(pendingMatch);
			}
		}

		for (let i = 0; i < pendingSchedule.length; i++) {
			const pendingMatch = pendingSchedule[i];

			// Dont save pending matches that are not fully filled out
			if (pendingMatch.TeamOneId === 0 || pendingMatch.TeamTwoId === 0) {
				continue;
			}

			const match = response.schedule.find((match) => match.Id === pendingMatch.Id);

			if (!match) {
				newMatches.push(pendingMatch);
			}
		}

		const request: UpdateScheduleRequest = {
			LeagueId: leagueId,
			NewMatches: newMatches,
			UpdatedMatches: updatedMatches,
			DeletedMatches: deletedMatches
		};

		updateScheduleRequest(request, token, setIsLoading);

		setIsEditing(false);
	};

	

	return (
		<>
			{response.schedule.length === 0 ? (
				<>
					{leagueOwner && (
						<Box>
							{!isEditing && (
								<>
									<Typography variant="body1" gutterBottom>
										There are no matchups scheduled for this season.
									</Typography>
									<Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
										Create schedule
									</Button>
								</>
							)}							
							{isEditing && (
								<>
									<ScheduleCreator
										teams={teams}
										pendingSchedule={pendingSchedule}
										setPendingSchedule={setPendingSchedule}
										saveScheduleUpdates={saveScheduleUpdates}
									/>
								</>
							)}
						</Box>
					)}
				</>
			) : (
					<Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden', maxWidth: '800px', minWidth: '400px' }}>
						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 1 }}>
							<Typography component="h2" variant="h6" gutterBottom>
								Matchups
							</Typography>
							<Box>
								{leagueOwner && !isEditing && (
									<Button variant="outlined" color="primary" onClick={() => setIsEditing(true)} sx={{ mr: 1 }}>
										Edit
									</Button>
								)}
								{leagueOwner && isEditing && (
									<>
										<Button variant="outlined" color="primary" onClick={() => setIsEditing(false)} sx={{ mr: 1 }}>
											Cancel
										</Button>
										<Button variant="outlined" color="primary" onClick={() => saveScheduleUpdates()} sx={{ mr: 1 }}>
											Save
										</Button>
									</>

								)}
								<FormControl sx={{ minWidth: '200px', maxWidth: '300px' }}>
									<InputLabel id="demo-simple-select-label">Week</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={selectedWeek}
										label="Week"
										onChange={(e) => setSelectedWeek(e.target.value)}
										native={isMobile} // Use native dropdown on mobile
									>
										{isMobile && getMenuItemsForSchedule(isEditing ? pendingSchedule : response.schedule).nativeOptions}
										{!isMobile && getMenuItemsForSchedule(isEditing ? pendingSchedule : response.schedule).options}
									</Select>
								</FormControl>
							</Box>
						</Box>
						{isEditing ? (
							<MatchupEditor pendingSchedule={pendingSchedule} setPendingSchedule={setPendingSchedule} teams={teams} selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
						) : (
							<>
								{response.schedule
									.filter((schedule: Schedule) => schedule.Week === Number(selectedWeek))
										.sort((a: Schedule, b: Schedule) => {
											// Priority sort for user's team
											const aIsUserTeam = a.TeamOneId === userTeamId || a.TeamTwoId === userTeamId;
											const bIsUserTeam = b.TeamOneId === userTeamId || b.TeamTwoId === userTeamId;
											if (aIsUserTeam && !bIsUserTeam) return -1; // a is user's team, move to start
											if (!aIsUserTeam && bIsUserTeam) return 1; // b is user's team, move to start

											// Null / Undefined sort
											if (a.ScheduledTime === null && b.ScheduledTime === null) return 0; // Both null, no change
											if (a.ScheduledTime === DEFAULT_TIME) return 1; // a is null, move it to the end
											if (b.ScheduledTime === DEFAULT_TIME) return -1; // b is null, move it to the end
											if (a.ScheduledTime === undefined || b.ScheduledTime === undefined) return 0; // Both undefined, no change

											// Normal time comparison
											return a.ScheduledTime.localeCompare(b.ScheduledTime);
									})
									.map((schedule: Schedule) => (
										<Box key={schedule.Id}>
											<MatchupCard
												team1={teams.find((team) => team.TeamId === schedule.TeamOneId)}
												team2={teams.find((team) => team.TeamId === schedule.TeamTwoId)}
												historicalMatch={historicalMatches.find((match) => match.Id === schedule.ReplaySubmissionId)}
												schedule={schedule}
												leagueId={leagueId}
												seasonId={seasonId}
												leagueOwner={leagueOwner}
												userTeamId={userTeamId}
												reload={reload}
											/>
										</Box>
									))}
							</>
						)}
					</Paper>
			)}			
		</>
    );
}
