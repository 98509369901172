import * as React from 'react';
import Box from '@mui/material/Box';
import { PokemonTeam } from '../../../types/PokemonTeam';
import { CircularProgress, Paper } from '@mui/material';
import { Token } from '../../../types/Token';
import useToken from '../../../useToken';
import { useEffect, useState } from 'react';
import Standings from './components/Standings';
import MatchHistory from './components/MatchHistory';
import { HistoricalMatch } from '../../../types/HistoricalMatch';
import Matchups from './components/Matchups';

async function getMatchHistoryRequest(seasonId: string, token: Token, setMatchHistory: any) {
	fetch('/Match/History/' + seasonId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			return;
		}

		setMatchHistory(message);
	});
}

interface LeagueTabProps {
	teams: PokemonTeam[],
	leagueId: string,
	seasonId: string,
	leagueOwner: boolean,
	userTeamId: number
}

export default function LeagueTab({ teams, leagueId, seasonId, leagueOwner, userTeamId }: LeagueTabProps) {
	const [matchHistory, setMatchHistory] = useState<HistoricalMatch[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const { token } = useToken();

	useEffect(() => {
		if (loading) {
			getMatchHistoryRequest(seasonId, token, setMatchHistory);
		}		
	}, [seasonId, token, loading]);

	useEffect(() => {
		setLoading(false);
	}, [matchHistory]);

	const reloadMatchHistory = () => {
		setLoading(true);
	}

	return (
		<>
			{(!teams || teams.length === 0) && (
				<Box>
					League standings can be viewed here after the draft is over.
				</Box>
			)}
			{teams.length > 0 && (
				<Box>
					<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
						<Box>
							{loading && (
								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>
							)}
							{!loading && (
								<Box>
									<Matchups leagueId={leagueId} seasonId={seasonId} leagueOwner={leagueOwner} teams={teams} historicalMatches={matchHistory} userTeamId={userTeamId} reloadMatchHistory={reloadMatchHistory} />
									<Standings teams={teams} matchHistory={matchHistory} />
									<MatchHistory teams={teams} matchHistory={matchHistory} leagueOwner={leagueOwner} />
								</Box>
							)}
						</Box>
					</Paper>
				</Box>
			)}
		</>
	);
}