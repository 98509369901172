import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TeamSettingsTab from './TeamSettingsTab';
import LeagueSettingsTab from './LeagueSettingsTab';
import DraftSettingsTab from './DraftSettingsTab';
import MemberSettingsTab from './MemberSettingsTab';
import UserSettingsTab from './UserSettingsTab';

interface Props {
    open: boolean;
    onClose: (value: string) => void;
    leagueId: string;
    seasonId?: string;
    leagueOwner?: boolean;
    defaultTab?: string;
}

export default function SettingsDialog(props: Props) {
    const { onClose, open, leagueId, seasonId, leagueOwner, defaultTab } = props;
    const [tabValue, setTabValue] = useState<string>(defaultTab ? defaultTab : "1");

    useEffect(() => {
        if (!leagueOwner) {
            setTabValue("1");
        }
    }, [leagueOwner]);

    useEffect(() => {
        if (defaultTab) {
            setTabValue(defaultTab);
        }
    }, [defaultTab]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
        setTabValue(newValue);
    };

    return (
        <Dialog fullWidth={true} maxWidth={'md'} onClose={onClose} open={open} >
            <Box sx={{ padding: '4px', display: 'flex' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
                        <TabList orientation="vertical" onChange={handleTabChange} aria-label="settings tabs">
                            <Tab label="User Settings" value="1" />
                            <Tab label="Team Settings" value="2" />
                            {leagueOwner && (
                                <Tab label="League Settings" value="3" />
                            )}
                            {leagueOwner && (
                                <Tab label="Draft Settings" value="4" />
                            )}
                            {leagueOwner && (
                                <Tab label="Member Settings" value="5" />
                            )}
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <UserSettingsTab />
                    </TabPanel>
                    <TabPanel value="2">
                        <TeamSettingsTab leagueId={leagueId} seasonId={seasonId} />
                    </TabPanel>
                    {leagueOwner && (
                        <TabPanel value="3">
                            <LeagueSettingsTab leagueId={leagueId} seasonId={seasonId} />
                        </TabPanel>
                    )}
                    {leagueOwner && (
                        <TabPanel value="4">
                            <DraftSettingsTab leagueId={leagueId} seasonId={seasonId} />
                        </TabPanel>
                    )}
                    {leagueOwner && (
                        <TabPanel value="5">
                            <MemberSettingsTab leagueId={leagueId} seasonId={seasonId} />
                        </TabPanel>
                    )}
                 </TabContext>
            </Box>
        </Dialog>
    );
}