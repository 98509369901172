import * as React from 'react';
import { PokemonTeam } from '../../../../types/PokemonTeam';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { HistoricalMatch } from '../../../../types/HistoricalMatch';
import { useMemo, useState } from 'react';
import useToken from '../../../../useToken';
import { Token } from '../../../../types/Token';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MatchStatsTable from '../../../Stats/MatchStatsTable';

interface MatchHistoryProps {
    teams: PokemonTeam[],
    matchHistory: HistoricalMatch[],
    leagueOwner: boolean,
}

async function sendDeleteMatchRequest(matchId: number, token: Token) {
    fetch('/Match/Delete/' + matchId, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        if (!response.ok) {
            alert("Failed to delete match");
            return;
        }

        alert("Match deleted successfully");
    });
}

function getRowsForMatchHistory(matchHistory: HistoricalMatch[], teams: PokemonTeam[], leagueOwner: boolean, token: Token) {
    // Get display names for all teams first
    let displayNameForTeamId = new Map<number, string>();
    for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        const displayName = team.TeamName && team.TeamName.length > 0 ? team.TeamName : team.OwnerDisplayName;
        displayNameForTeamId.set(team.TeamId, displayName);
    }

    // Build rows
    let rows = [];
    for (let i = 0; i < matchHistory.length; i++) {
        const match = matchHistory[i];

        let dateObj = new Date(match.SubmittedAt);
        let formattedDate = dateObj.toLocaleString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        let winnerTeamId = match.Winner === match.PlayerOne ? match.PlayerOneTeamId : match.PlayerTwoTeamId;

        // If the match has no players or url, then the winning team id is set in the winner field
        if (match.PlayerOne === "" && match.PlayerTwo === "") {
            winnerTeamId = Number(match.Winner);
        }

        rows.push(
            <ExpandableTableRow key={i} expandComponent={
                <MatchStatsTable matchId={match.Id} teams={teams} />
            }>
                <TableCell key={'matchup' + i}>{displayNameForTeamId.get(match.PlayerOneTeamId)} vs. {displayNameForTeamId.get(match.PlayerTwoTeamId)}</TableCell>
                <TableCell key={'winner' + i}>{displayNameForTeamId.get(winnerTeamId)}</TableCell>
                <TableCell key={'date' + i}>{formattedDate}</TableCell>
                <TableCell key={'replay' + i}>
                    {match.ReplayUrl !== "" ? (
                        <a href={match.ReplayUrl} target="_blank" rel="noopener noreferrer">View Replay</a>
                    ) : (
                        <Box>
                            No replay available
                        </Box>
                    )}

                </TableCell>
                {leagueOwner && (
                    <TableCell key={'delete' + i}>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => sendDeleteMatchRequest(match.Id, token)}
                        >
                            Delete
                        </Button>
                    </TableCell>
                )}
            </ExpandableTableRow>
        );
    }
    return rows;
}

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }: any) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell padding="none" colSpan={children.length + 1}>
                        {expandComponent}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default function MatchHistory({ matchHistory, teams, leagueOwner }: MatchHistoryProps) {
    const { token } = useToken();
    const rows = useMemo(() => getRowsForMatchHistory(matchHistory, teams, leagueOwner, token), [matchHistory, teams, leagueOwner, token]);

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, m: 2, overflow: 'hidden' }}>
                <Typography component="h2" variant="h6" gutterBottom>
                    Match History
                </Typography>
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow key={'matchHistoryHeader'}>
                                <TableCell key={'expand'}>Expand</TableCell>
                                <TableCell key={'matchup'}>Matchup</TableCell>
                                <TableCell key={'winner'}>Winner</TableCell>
                                <TableCell key={'date'}>Date (EST)</TableCell>
                                <TableCell key={'replay'}>Replay</TableCell>
                                {leagueOwner && <TableCell key={'delete'}>Delete</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}